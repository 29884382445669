// eslint-disable-next-line prettier/prettier
import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, type FormikValues } from 'formik';
import { type FunctionComponent } from 'react';
import toast from 'react-hot-toast';

import updateOutcomeByLoanIdMutation from '../../apis/graphql/mutation/update-outcome-by-loan-id-mutation';
import { approvalOptions, approvalStatusOptions } from '../../constants/approval-status';
import { type Data } from '../../data/loan-applications-data';
import initialState from '../../pages/credit-assessment/validations/initial-state';
import validationSchema from '../../pages/credit-assessment/validations/validation-schema';
import { defaultTheme } from '../../theme/default-theme';
import { type UpdateOutcomeByLoanIdParams } from '../../types/Outcomes';
import SelectInputField from '../molecules/SelectInputField';
import TextInputField from '../molecules/TextInputField';
import TextareaInputField from '../molecules/TextareaInputField';

interface ApprovedFormProps {
	data: Data | null;
	toggleDrawer: () => void;
}

const ApprovedForm: FunctionComponent<ApprovedFormProps> = ({ toggleDrawer = null, data }) => {
	const queryClient = useQueryClient();

	const handleCloseDetailsModal = () => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (toggleDrawer) {
			toggleDrawer();
		}
	};

	const { isPending, mutateAsync, isError, error } = useMutation({
		mutationFn: async (data: UpdateOutcomeByLoanIdParams) => await updateOutcomeByLoanIdMutation(data),
		onSuccess: () => {
			toast.success('Loan approved successfully');
			queryClient.invalidateQueries({ queryKey: ['credit_assessment_loan-property-applicant-outcome'] });
			handleCloseDetailsModal();
		},
	});

	const handleSubmitApproval = async (values: FormikValues) => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (!data?.uuid) {
			return;
		}

		const inputData: UpdateOutcomeByLoanIdParams = {
			loanId: data.uuid,
			approval: values.approval === 'YES',
			approvalStatus: values.approvalStatus,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			updatedAt: new Date(values.approvalDate).toISOString(),
			note: values.note,
		};

		try {
			await mutateAsync(inputData);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={handleSubmitApproval}>
			<fieldset style={{ border: 'none' }}>
				<Form>
					<Box
						sx={{
							border: `1px solid ${defaultTheme.primary.main}`,
							marginTop: 4,
							borderRadius: '5px',
							p: 4,
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
						}}
					>
						{isError && <Alert severity="error">{error.message}</Alert>}

						<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Grid item xs={3} sm={3} md={3}>
								<Typography sx={{ fontWeight: 'semibold' }}>
									Approval <span style={{ color: defaultTheme.warning.main }}>*</span>
								</Typography>
							</Grid>

							<Grid item xs={9} sm={9} md={9}>
								<SelectInputField
									isRequired
									fullWidth
									name="approval"
									title=""
									label="Approval"
									items={approvalOptions}
								/>
							</Grid>
						</Grid>

						<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Grid item xs={3} sm={3} md={3}>
								<Typography sx={{ fontWeight: 'semibold' }}>
									Approval status <span style={{ color: defaultTheme.warning.main }}>*</span>
								</Typography>
							</Grid>

							<Grid item xs={9} sm={9} md={9}>
								<SelectInputField
									isRequired
									fullWidth
									name="approvalStatus"
									title=""
									label="Approval status"
									items={approvalStatusOptions}
								/>
							</Grid>
						</Grid>

						<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Grid item xs={3} sm={3} md={3}>
								<Typography sx={{ fontWeight: 'semibold' }}>
									Approval date <span style={{ color: defaultTheme.warning.main }}>*</span>
								</Typography>
							</Grid>

							<Grid item xs={9} sm={9} md={9}>
								<TextInputField isRequired fullWidth type="date" name="approvalDate" title="" label="" />
							</Grid>
						</Grid>

						<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
							<Grid item xs={3} sm={3} md={3}>
								<Typography sx={{ fontWeight: 'semibold' }}>Note</Typography>
							</Grid>

							<Grid item xs={9} sm={9} md={9}>
								<TextareaInputField
									fullWidth
									name="note"
									title=""
									titleStyle={{ color: defaultTheme.primary.dark }}
									label="Some label"
									placeholder="Write a note"
									inputStyle={{
										display: 'inline-block',
										width: '100%',
										border: `1px solid ${defaultTheme.secondary.contrastText}`,
										borderRadius: '5px',
										padding: '5px',
										fontSize: '16px',
									}}
								/>
							</Grid>
						</Grid>

						<Box sx={{ display: 'flex', gap: 2 }}>
							<Button
								type="submit"
								variant="contained"
								endIcon={isPending && <CircularProgress size={18} color="inherit" />}
							>
								Save
							</Button>

							<Button
								type="button"
								variant="outlined"
								sx={{ border: '1px solid transparent' }}
								onClick={handleCloseDetailsModal}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</Form>
			</fieldset>
		</Formik>
	);
};

export default ApprovedForm;
