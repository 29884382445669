import { type Dispatch } from 'react';

import { ActionType } from '../types/Breadcrumb';

export const setBreadcrumb = (dispatch: Dispatch<any>, payload: string) => {
	dispatch({
		type: ActionType.SET_BREADCRUMB,
		payload,
	});
};
