import { AccountBalance, Help, Person, Settings } from '@mui/icons-material';

import type Route from '../types/Route';

export const headerRoutes: Route[] = [
	{
		id: 'deb346bb-db95-4ae1-b130-28d058686101',
		name: 'Profile',
		component: () => null,
		path: '/profile',
		icon: Person,
		exact: true,
		protected: false,
	},
	{
		id: 'deb346bb-db95-4ae1-b130-28d058686100',
		name: 'Accounts',
		component: () => null,
		path: '/accounts',
		icon: AccountBalance,
		exact: true,
		protected: false,
		divider: true,
	},
	{
		id: '2f94c71f-c79f-426b-be7c-cfbcd41d1350',
		name: 'More',
		component: () => null,
		path: '/more',
		icon: Settings,
		exact: true,
		protected: false,
	},
	{
		id: '2f94c71f-c79f-426b-be7c-cfbcd41d1351',
		name: 'Get Help',
		component: () => null,
		path: 'https://support.ourlegup.com/portal/en/newticket',
		icon: Help,
		exact: true,
		protected: false,
		divider: true,
		openInNewTab: true,
	},
];
