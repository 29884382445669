import { useFormControl } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useMemo, type FunctionComponent } from 'react';

interface InputHelperTextPropsTypes {
	error: string | undefined;
	testid: string;
}

const InputHelperText: FunctionComponent<InputHelperTextPropsTypes> = ({ error = '', testid = '' }) => {
	const { focused } = useFormControl() ?? {};

	const helperText = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (focused) return null;

		return error;
	}, [error, focused]);

	return (
		<FormHelperText data-testid={testid} sx={{ ml: 0, color: (theme) => theme.palette.warning.main }}>
			{helperText}
		</FormHelperText>
	);
};

export default InputHelperText;
