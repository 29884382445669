import { ArrowDropDown, Logout } from '@mui/icons-material';
// eslint-disable-next-line prettier/prettier
import { Avatar, Backdrop, Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useState, type MouseEvent } from 'react';

import MenuItemWithIcon from '../../components/atoms/MenuItemWithIcon';
import { drawerWidth } from '../../constants/layout';
import { headerRoutes } from '../../routes/header-routes';
import type Route from '../../types/Route';

const UserProfileMenu = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	// eslint-disable-next-line prettier/prettier
	const handleClick = (event: MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); };

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
				<Tooltip title="Account settings" sx={{ '&:hover': { background: 'none' } }}>
					<Button
						onClick={handleClick}
						size="small"
						sx={{ ml: 2, '&:hover': { background: 'none' } }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								gap: { xs: 0.5, sm: 1 },
							}}
						>
							<Avatar
								sx={{
									bgcolor: (theme) => theme.palette.primary.main,
									height: { xs: 30, sm: 42 },
									width: { xs: 30, sm: 42 },
								}}
							>
								D
							</Avatar>

							<Typography sx={{ textTransform: 'none' }}>David</Typography>
							<ArrowDropDown />
						</Box>
					</Button>
				</Tooltip>
			</Box>

			<Backdrop
				sx={{
					color: (theme) => theme.palette.secondary.main,
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={open}
				onClick={handleClose}
			>
				<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					slotProps={{
						paper: {
							elevation: 0,
							sx: {
								minWidth: `${drawerWidth}px`,
								p: 1,
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					{headerRoutes.map((item: Route) => (
						<MenuItemWithIcon key={item.id} item={item} />
					))}

					<MenuItem>
						<ListItemIcon
							sx={{
								display: 'flex',
								justifyContent: 'start',
								alignItems: 'center',
								gap: 1.5,
								color: (theme) => theme.palette.primary.main,
							}}
						>
							<Logout />
						</ListItemIcon>

						<ListItemText primary="Logout" sx={{ color: (theme) => theme.palette.primary.main }} />
					</MenuItem>
				</Menu>
			</Backdrop>
		</>
	);
};

export default UserProfileMenu;
