import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	approval: Yup.string().required('Approval is required'),
	approvalStatus: Yup.string().required('Approval status is required'),
	approvalDate: Yup.string().required('Approval date is required'),
});

export const notesValidationSchema = Yup.object().shape({
	note: Yup.string().required('Note is required'),
});

export default validationSchema;
