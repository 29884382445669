import { ArrowForwardIos } from '@mui/icons-material';
import { Checkbox, Drawer, IconButton, LinearProgress, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect, useMemo, useState, type ChangeEvent, type FunctionComponent, type MouseEvent } from 'react';

import { CREDIT_ASSESSMENT_DRAWER_DEFAULT_ANCHOR } from '../../constants/layout';
import { headerRows, rows, type Data } from '../../data/loan-applications-data';
import { type Order } from '../../types/CommonTypes';
import { formatNumberToCurrency } from '../../utils/format-number-to-currency';
import { getComparator, stableSort } from '../../utils/sort-functions';
import TransactionLogAction from '../molecules/TransactionLogAction';
import CreditAssessmentTableHeaders from '../organisms/CreditAssessmentTableHeaders';
import SelectedItemsTableToolbar from '../organisms/SelectedItemsTableToolbar';
import TransactionLogDetails from './TransactionLogDetails';

interface TransactionLogTableProps {
	data: Data[];
	loading: boolean;
}

const TransactionLogTable: FunctionComponent<TransactionLogTableProps> = ({ data = [], loading = false }) => {
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<keyof Data>('id');
	const [selected, setSelected] = useState<readonly number[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState<Data | null>(null);

	const toggleDrawer = () => {
		if (setIsDrawerOpen !== null) {
			setIsDrawerOpen((prev: boolean) => !prev);
		}
	};

	const handleViewDetails = (event: MouseEvent<HTMLButtonElement>, item: Data) => {
		setSelectedItem(item);
		event.stopPropagation();
		toggleDrawer();
	};

	const handleRequestSort = (event: MouseEvent<unknown>, property: keyof Data) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: MouseEvent<unknown>, id: number) => {
		event.stopPropagation();

		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (id: number) => selected.includes(id);

	const visibleRows = useMemo(
		() => stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[data, order, orderBy, page, rowsPerPage]
	);

	useEffect(
		() => () => {
			setIsDrawerOpen(false);
		},
		[]
	);

	return (
		<>
			{loading ? <LinearProgress color="success" /> : null}

			<Box sx={{ width: '100%' }}>
				{selected.length > 0 ? <SelectedItemsTableToolbar numSelected={selected.length} /> : null}

				<TableContainer>
					{!loading && data?.length > 0 && (
						<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
							<CreditAssessmentTableHeaders
								headerRows={headerRows}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>

							<TableBody>
								{visibleRows.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => {
												handleClick(event, row.id);
											}}
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											sx={{
												cursor: 'pointer',
												// TODO: Color status changes to showcasing the demo output
												background: row.status === 1 ? '#c9ec8380' : 'none',
											}}
										>
											<TableCell padding="checkbox">
												<Checkbox
													color="primary"
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>

											<TableCell component="th" id={labelId} scope="row" padding="none">
												#{row.id}
											</TableCell>
											<TableCell align="right">{row.loanType}</TableCell>
											<TableCell align="right">{row.applicantType}</TableCell>
											<TableCell align="right">{formatNumberToCurrency(parseInt(row.loanBalance))}</TableCell>
											<TableCell align="right">{formatNumberToCurrency(parseInt(row.propertyValuation))}</TableCell>
											<TableCell align="right">{row.propertyAddress}</TableCell>
											<TableCell align="right">{row.lga}</TableCell>
											<TableCell align="right">
												<TransactionLogAction action={row.status} approvalStatus={row.approvalStatus} />
											</TableCell>
											<TableCell align="right">
												<Tooltip title="View Details">
													<IconButton
														onClick={(event) => {
															handleViewDetails(event, row);
														}}
													>
														<ArrowForwardIos />
													</IconButton>
												</Tooltip>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					)}
				</TableContainer>

				{!loading && data?.length > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Box>

			<Drawer anchor={CREDIT_ASSESSMENT_DRAWER_DEFAULT_ANCHOR} open={isDrawerOpen} onClose={toggleDrawer}>
				<TransactionLogDetails data={selectedItem} toggleDrawer={toggleDrawer} />
			</Drawer>
		</>
	);
};

export default TransactionLogTable;
