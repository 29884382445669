import { Box, Typography } from '@mui/material';
import Assigned from '../../assets/svgs/Assigned';
import NotApproved from '../../assets/svgs/NotApproved';
import Submitted from '../../assets/svgs/Submitted';

interface TransactionLogActionProps {
	action: number | string;
	approvalStatus: string;
}

const TransactionLogAction = (props: TransactionLogActionProps) => {
	if (props.action === 0)
		return (
			<Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 0.5 }}>
				<NotApproved />
				<Typography variant="body2">Review Manually</Typography>
			</Box>
		);

	// TODO: Reverted the status so that can achieve the desired output for demo

	if (props.action === 1)
		return (
			<Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 0.5 }}>
				<Submitted />
				<Typography variant="body2">
					{['CONDITIONAL', 'UNCONDITIONAL'].includes(props.approvalStatus)
						? 'Manually Approved'
						: 'Automatically Approved'}
				</Typography>
			</Box>
		);

	if (props.action === 2)
		return (
			<Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 0.5 }}>
				<Assigned />
				<Typography variant="body2">Submitted to Bank</Typography>
			</Box>
		);
};

export default TransactionLogAction;
