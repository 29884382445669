import { Box, Grid, Typography } from '@mui/material';

import EmploymentApplicantsTable from '../organisms/EmploymentApplicantsTable';
import HEMCalculationsTable from '../organisms/HEMCalculationsTable';
import OtherLoanCommitmentsTable from '../organisms/OtherLoanCommitmentsTable';

const DisposableIncomeDetails = () => {
	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={12} md={12}>
					<Box>
						<Typography variant="h5" mb={2} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							HEM Calculations
						</Typography>

						<HEMCalculationsTable />
					</Box>
				</Grid>
			</Grid>

			<Box my={5} />

			<Grid container>
				<Grid item xs={12} sm={12} md={12}>
					<Box>
						<Typography variant="h5" mb={2} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							Other Loan Commitments
						</Typography>

						<OtherLoanCommitmentsTable />
					</Box>
				</Grid>
			</Grid>

			<Box my={5} />

			<Grid container>
				<Grid item xs={12} sm={12} md={12}>
					<Box>
						<Typography variant="h5" mb={2} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							Employment
						</Typography>

						<EmploymentApplicantsTable />
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default DisposableIncomeDetails;
