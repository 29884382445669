import { type Dispatch } from 'react';

export interface State {
	open: boolean;
}

export interface Action {
	type: string;
	payload: boolean;
}

export interface Context {
	state: State;
	dispatch: Dispatch<any>;
}

export enum ActionType {
	OPEN_SIDEBAR_DRAWER = 'OPEN_SIDEBAR_DRAWER',
	CLOSE_SIDEBAR_DRAWER = 'CLOSE_SIDEBAR_DRAWER',
}
