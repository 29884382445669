// eslint-disable-next-line prettier/prettier
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

const HEMReEmploymentTable = () => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="applicant income table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									background: (theme) => theme.palette.secondary.light,
									color: (theme) => theme.palette.secondary.dark,
									textTransform: 'uppercase',
									fontWeight: 400,
								}}
								align="left"
							>
								Re-employment Rate
							</TableCell>

							<TableCell
								sx={{
									background: (theme) => theme.palette.secondary.main,
									fontWeight: 400,
								}}
								align="center"
							>
								43.78%
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								sx={{
									background: (theme) => theme.palette.secondary.light,
									color: (theme) => theme.palette.secondary.dark,
									textTransform: 'uppercase',
								}}
								component="th"
								scope="row"
							>
								Pay Rises
							</TableCell>
							<TableCell
								sx={{
									background: (theme) => theme.palette.secondary.main,
								}}
								align="center"
							>
								{formatNumberToCurrency(13344)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default HEMReEmploymentTable;
