import api from '../../config/instance';

const notesByLoanIdQuery = async (loanId: string) => {
	const graphqlQuery = {
		query: `
	    query credit_assessment_notes($loan_id: uuid) {
        credit_assessment_notes(where: { loan_id: { _eq: $loan_id } }) {
          id
          note
          note_by
          created_at
          updated_at
        }
      }
	  `,
		variables: {
			loan_id: loanId,
		},
	};

	try {
		const response = await api.post('/', graphqlQuery);

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (response.status === 200 && response.data?.data?.credit_assessment_notes) {
			return await Promise.resolve(response.data?.data?.credit_assessment_notes);
		}

		return await Promise.reject(response.data?.errors);
	} catch (error: any) {
		console.log(error);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		throw new Error(String(error?.[0]?.message || error?.message || error));
	}
};

export default notesByLoanIdQuery;
