import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { type FunctionComponent } from 'react';

import { APPLICANT_TYPE_COUPLE } from '../../constants/credit-constants';
import { type Data } from '../../data/loan-applications-data';
import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

interface CreditAssessmentApplicantInformationProps {
	data: Data | null;
}

const CreditAssessmentApplicantInformation: FunctionComponent<CreditAssessmentApplicantInformationProps> = ({
	data,
}) => {
	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={12} mt={4}>
				<Box px={4}>
					<Accordion component={Box} defaultExpanded>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="applicant-information-content"
							id="applicant-information-header"
						>
							<Typography>Applicant Information</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
								<Typography fontWeight="bold">Applicant 1</Typography>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Age:</Typography>
									<Typography sx={{ flex: 1 }}>{data?.applicant1Age}</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Female:</Typography>
									<Typography sx={{ flex: 1 }}>
										{data?.applicant1Female?.toUpperCase() === 'TRUE' ? 'Yes' : 'No'}
									</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>PAYG Income:</Typography>
									<Typography sx={{ flex: 1 }}>
										{data?.applicant1PAYGIncome != null
											? formatNumberToCurrency(parseInt(data?.applicant1PAYGIncome, 10))
											: ''}
									</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Other Income:</Typography>
									<Typography sx={{ flex: 1 }}>
										{data?.applicant1otherIncome != null
											? formatNumberToCurrency(parseInt(data?.applicant1otherIncome, 10))
											: ''}
									</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Public Sector:</Typography>
									<Typography sx={{ flex: 1 }}>
										{data?.applicant1PublicSector?.toUpperCase() === 'TRUE' ? 'Yes' : 'No'}
									</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Description of Occupation:</Typography>
									<Typography sx={{ flex: 1 }}>
										{/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
										{data?.applicant1DescriptionOfOccupation
											? data?.applicant1DescriptionOfOccupation
											: 'Not Available'}
									</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Description of Industry:</Typography>
									<Typography sx={{ flex: 1 }}>
										{/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
										{data?.applicant1DescriptionOfIndustry ? data?.applicant1DescriptionOfIndustry : 'Not Available'}
									</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Duration of Employment:</Typography>
									<Typography sx={{ flex: 1 }}>{data?.applicant1DurationOfEmployment}</Typography>
								</Box>

								<Box sx={{ display: 'flex', gap: 5 }}>
									<Typography sx={{ flex: 1 }}>Employment Type:</Typography>
									<Typography sx={{ flex: 1 }}>
										{data?.applicant1EmploymentType?.toUpperCase() === 'FALSE' ? 'Permanent' : 'Temporary'}
									</Typography>
								</Box>
							</Box>

							{data?.applicantType?.toUpperCase() === APPLICANT_TYPE_COUPLE && (
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 5 }}>
									<Typography fontWeight="bold">Applicant 2</Typography>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Age:</Typography>
										<Typography sx={{ flex: 1 }}>{data?.applicant2Age}</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Female:</Typography>
										<Typography sx={{ flex: 1 }}>
											{data?.applicant2Female?.toUpperCase() === 'TRUE' ? 'Yes' : 'No'}
										</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>PAYG Income:</Typography>
										<Typography sx={{ flex: 1 }}>
											{data?.applicant2PAYGIncome != null
												? formatNumberToCurrency(parseInt(data?.applicant2PAYGIncome, 10))
												: ''}
										</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Other Income:</Typography>
										<Typography sx={{ flex: 1 }}>
											{data?.applicant2otherIncome != null
												? formatNumberToCurrency(parseInt(data?.applicant2otherIncome, 10))
												: ''}
										</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Public Sector:</Typography>
										<Typography sx={{ flex: 1 }}>
											{data?.applicant2PublicSector?.toUpperCase() === 'TRUE' ? 'Yes' : 'No'}
										</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Description of Occupation:</Typography>
										<Typography sx={{ flex: 1 }}>
											{/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
											{data?.applicant2DescriptionOfOccupation
												? data?.applicant2DescriptionOfOccupation
												: 'Not Available'}
										</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Description of Industry:</Typography>
										<Typography sx={{ flex: 1 }}>
											{/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
											{data?.applicant2DescriptionOfIndustry ? data?.applicant2DescriptionOfIndustry : 'Not Available'}
										</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Duration of Employment:</Typography>
										<Typography sx={{ flex: 1 }}>{data?.applicant2DurationOfEmployment}</Typography>
									</Box>

									<Box sx={{ display: 'flex', gap: 5 }}>
										<Typography sx={{ flex: 1 }}>Employment Type:</Typography>
										<Typography sx={{ flex: 1 }}>
											{data.applicant2EmploymentType?.toUpperCase() === 'FALSE' ? 'Permanent' : 'Temporary'}
										</Typography>
									</Box>
								</Box>
							)}
						</AccordionDetails>
					</Accordion>
				</Box>
			</Grid>
		</Grid>
	);
};

export default CreditAssessmentApplicantInformation;
