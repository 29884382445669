const formatLoansData = (loansData: any[]) =>
	loansData.map((item: any, index: number) => ({
		id: index + 1,
		uuid: String(item?.id),
		loanType: String(item?.loan_type),
		applicantType: String(item?.application_type),
		loanBalance: String(item?.loan_balance),
		propertyValuation: String(item?.property_valuation),
		propertyAddress: String(item?.properties[0]?.address),
		lga: String(item?.properties[0]?.lga),
		status: Number(item?.assessment_outcomes[0]?.approve_loan === true ? 1 : 0),
		approvalStatus: String(item?.assessment_outcomes[0]?.approval_status),
		incomeToLoanRatio: String(item?.assessment_outcomes[0]?.loan_to_income_ratio),
		shareDisposableIncomeBuffer: String(item?.assessment_outcomes[0]?.share_disposal_income_buffer),
		lossShare: String(item?.assessment_outcomes[0]?.loss_share),
		loanDocuments: String(item?.loan_documents),
		privateHealthInsurance: String(item?.private_health_insurance),
		originalLoan: String(item?.existing_loan),
		interestRate: String(item?.interest_rate),
		numberOfApplicants: Number(item?.number_of_applicants),
		propertyType: String(item?.properties[0]?.property_type),
		strataTitle: String(item?.properties[0]?.strata_title),
		suburb: String(item?.properties[0]?.suburb),
		state: String(item?.properties[0]?.state),
		postcode: String(item?.properties[0]?.postcode),
		sale: String(item?.properties[0]?.sale),
		valuationDate: String(item?.valuation_date),
		termOfLoan: String(item?.term_of_loan),
		oluLoanDuration: String(item?.olu_loan_duration),
		applicant1Age: String(item?.applicants[0]?.age),
		applicant1Female: String(item?.applicants[0]?.female),
		applicant1PAYGIncome: String(item?.applicants[0]?.payg_income),
		applicant1otherIncome: String(item?.applicants[0]?.other_income),
		applicant1PublicSector: String(item?.applicants[0]?.public_sector),
		applicant1Occupation: Number(item?.applicants[0]?.occupation),
		applicant1DescriptionOfOccupation: String(item?.applicants[0]?.occupation_description),
		applicant1Industry: Number(item?.applicants[0]?.industry),
		applicant1DescriptionOfIndustry: String(item?.applicants[0]?.industry_description),
		applicant1DurationOfEmployment: String(item?.applicants[0]?.duration_of_employment),
		applicant1EmploymentType: String(item?.applicants[0]?.employment_casual),
		applicant2Age: String(item?.applicants[1]?.age),
		applicant2Female: String(item?.applicants[1]?.female),
		applicant2PAYGIncome: String(item?.applicants[1]?.payg_income),
		applicant2otherIncome: String(item?.applicants[1]?.other_income),
		applicant2PublicSector: String(item?.applicants[1]?.public_sector),
		applicant2Occupation: Number(item?.applicants[1]?.occupation),
		applicant2DescriptionOfOccupation: String(item?.applicants[1]?.occupation_description),
		applicant2Industry: Number(item?.applicants[1]?.industry),
		applicant2DescriptionOfIndustry: String(item?.applicants[1]?.industry_description),
		applicant2DurationOfEmployment: String(item?.applicants[1]?.duration_of_employment),
		applicant2EmploymentType: String(item?.applicants[1]?.employment_casual),
	}));

export default formatLoansData;
