import axios from 'axios';

const api = axios.create({
	baseURL: process.env.REACT_APP_HASURA_GRAPHQL_API_ENDPOINT,
	headers: {
		'content-type': 'application/json',
		'x-hasura-admin-secret': process.env.REACT_APP_X_HASURA_ADMIN_SECRET,
	},
});

export default api;
