import { Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState, type FunctionComponent } from 'react';

import approvalStatusQuery from '../../apis/graphql/query/approval-status-query';
import { APPROVED, ASSIGN_TO, REVIEW_MANUALLY, SUBMIT_TO_BANK } from '../../constants/approval-status';
import { CREDIT_ASSESSMENT_DRAWER_WIDTH } from '../../constants/layout';
import { type Data } from '../../data/loan-applications-data';
import { defaultTheme } from '../../theme/default-theme';
import { type Select as SelectType } from '../../types/Select';
import isEmpty from '../../utils/is-empty';
import ApprovedForm from '../organisms/ApprovedForm';
import AssignToForm from '../organisms/AssignToForm';
import CreditAssessmentApplicantInformation from '../organisms/CreditAssessmentApplicantInformation';
import CreditAssessmentBasicInformation from '../organisms/CreditAssessmentBasicInformation';
import CreditAssessmentLoanInformation from '../organisms/CreditAssessmentLoanInformation';
import CreditAssessmentNotes from '../organisms/CreditAssessmentNotes';
import CreditAssessmentOutcomeInformation from '../organisms/CreditAssessmentOutcomeInformation';
import SubmitToBankForm from '../organisms/SubmitToBankForm';

interface TransactionLogDetailsProps {
	data: Data | null;
	toggleDrawer: () => void;
}

const TransactionLogDetails: FunctionComponent<TransactionLogDetailsProps> = ({ data, toggleDrawer }) => {
	/* 
  The code snippet you provided is setting the `approvalStatusDefaultValue` based on the
  `data?.status` value. It is using a conditional ternary operator to determine the value of
  `approvalStatusDefaultValue`
  */
	const approvalStatusDefaultValue =
		data?.status === 0
			? REVIEW_MANUALLY
			: data?.status === 1
				? APPROVED
				: data?.status === 2
					? SUBMIT_TO_BANK
					: data?.status === 3
						? ASSIGN_TO
						: '';

	const [approvalStatus, setApprovalStatus] = useState(approvalStatusDefaultValue);
	const [approvalStatusOptions, setApprovalStatusOptions] = useState<SelectType[] | []>([]);

	const {
		data: approvalStatusData,
		isLoading: approvalStatusDataIsLoading,
		isError: approvalStatusDataIsError,
		error: approvalStatusDataError,
	} = useQuery({
		queryKey: ['credit_assessment_approval_status'],
		queryFn: async () => await approvalStatusQuery(),
	});

	const handleCloseDetailsModal = () => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (toggleDrawer) {
			toggleDrawer();
		}
	};

	useEffect(() => {
		if (!isEmpty(approvalStatusData)) {
			const formattedData: SelectType[] | [] = approvalStatusData.map((item: any) => ({
				id: item.value,
				label: item.label,
				value: item.value,
			}));

			setApprovalStatusOptions(formattedData);
		}
	}, [approvalStatusData]);

	return (
		<Box sx={{ width: CREDIT_ASSESSMENT_DRAWER_WIDTH, py: 5 }} role="presentation">
			<Grid container>
				<Grid item xs={12} sm={12} md={12}>
					<Box px={4}>
						{approvalStatusDataIsError && (
							<Alert severity="error" sx={{ mt: 2, mb: 3 }}>
								{approvalStatusDataError.message.length > 0
									? approvalStatusDataError.message
									: 'An error occurred while fetching the banks data'}
							</Alert>
						)}
					</Box>

					<Box px={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex', gap: 2 }}>
							<Typography>LOAN ID:</Typography>
							<Typography>#{data?.id}</Typography>
						</Box>

						<Button onClick={handleCloseDetailsModal}>Close</Button>
					</Box>
				</Grid>

				<Grid item xs={12} sm={12} md={12} mt={4}>
					<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2, px: 4 }}>
						<Typography variant="subtitle1" pb={1} sx={{ color: defaultTheme.primary.dark, fontWeight: 600 }}>
							Approval:
						</Typography>

						<FormControl variant="outlined" fullWidth>
							<InputLabel htmlFor="outlined-select-input-adornment-for-approval-status-label">
								<span>
									Approval Status
									<span style={{ color: 'red' }}> * </span>
								</span>
							</InputLabel>

							<Select
								disabled={approvalStatusDataIsLoading}
								labelId="outlined-select-input-adornment-for-approval-status-label"
								id="outlined-select-input-adornment-for-approval-status"
								label="Approval Status * "
								sx={{ width: 200 }}
								value={approvalStatus}
								onChange={(event) => {
									setApprovalStatus(event.target.value);
								}}
							>
								<MenuItem value="">NONE</MenuItem>

								{approvalStatusOptions?.map((item) => (
									<MenuItem key={item?.id} value={item?.value}>
										{item?.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
				</Grid>

				<Grid item xs={12} sm={12} md={12}>
					<Box px={2.5}>
						{approvalStatus === APPROVED && data?.status !== 1 && (
							<ApprovedForm data={data} toggleDrawer={toggleDrawer} />
						)}
					</Box>

					<Box px={2.5}>{approvalStatus === SUBMIT_TO_BANK && <SubmitToBankForm toggleDrawer={toggleDrawer} />}</Box>

					<Box px={2.5}>{approvalStatus === ASSIGN_TO && <AssignToForm toggleDrawer={toggleDrawer} />}</Box>
				</Grid>

				<CreditAssessmentOutcomeInformation data={data} />

				{data?.status === 0 && <CreditAssessmentNotes data={data} />}

				<CreditAssessmentBasicInformation data={data} />

				<CreditAssessmentLoanInformation data={data} />

				<CreditAssessmentApplicantInformation data={data} />
			</Grid>
		</Box>
	);
};

export default TransactionLogDetails;
