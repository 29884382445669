import { type Select } from '../types/Select';

export const REVIEW_MANUALLY = 'REVIEW_MANUALLY';
export const NOT_APPROVED = 'NOT_APPROVED';
export const APPROVED = 'APPROVED';
export const SUBMIT_TO_BANK = 'SUBMIT_TO_BANK';
export const ASSIGN_TO = 'ASSIGN_TO';

export const approvalStatuses: Select[] = [
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde2',
		label: 'Review manually',
		value: REVIEW_MANUALLY,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde3',
		label: 'Not approved',
		value: NOT_APPROVED,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde4',
		label: 'Approved',
		value: APPROVED,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde5',
		label: 'Submit to Bank',
		value: SUBMIT_TO_BANK,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde6',
		label: 'Assign to',
		value: ASSIGN_TO,
	},
];

export const approvalOptions = [
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04eddf1',
		label: 'Yes',
		value: 'YES',
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04eddf2',
		label: 'No',
		value: 'NO',
	},
];

export const approvalStatusOptions = [
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04eddf1',
		label: 'Conditional',
		value: 'CONDITIONAL',
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04eddf2',
		label: 'Unconditional',
		value: 'UNCONDITIONAL',
	},
];
