import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Lock from '../../assets/svgs/Lock';
import DisposableIncomeDetails from '../../components/templates/DisposableIncomeDetails';
import LoanToIncomeRatioDetails from '../../components/templates/LoanToIncomeRatioDetails';
import LossProvisionDetails from '../../components/templates/LossProvisionDetails';
// eslint-disable-next-line prettier/prettier
import approvalStatusQuery from '../../apis/graphql/query/approval-status-query';
// eslint-disable-next-line prettier/prettier
import { DISPOSABLE_INCOME, LOAN_TO_INCOME_RATIO, LOSS_PROVISION, creditAssessmentDetailsOptions } from '../../constants/credit-assessment-options';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { setBreadcrumbText } from '../../shared';
import { type Select as SelectType } from '../../types/Select';
import isEmpty from '../../utils/is-empty';

const CreditAssessmentDetails = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const currentState = searchParams.get('state');
	const [selectedCurrentState, setSelectedCurrentState] = useState(currentState ?? LOAN_TO_INCOME_RATIO);

	const [approvalStatus, setApprovalStatus] = useState('');
	const [approvalStatusOptions, setApprovalStatusOptions] = useState<SelectType[] | []>([]);
	const { dispatch } = useBreadcrumb();

	const {
		data: approvalStatusData,
		isLoading: approvalStatusDataIsLoading,
		isError: approvalStatusDataIsError,
		error: approvalStatusDataError,
	} = useQuery({
		queryKey: ['credit_assessment_approval_status'],
		queryFn: async () => await approvalStatusQuery(),
	});

	const handleChangeCurrentState = (event: { target: { value: string } }) => {
		const { value } = event.target;
		setSelectedCurrentState(value);
		setSearchParams({ state: value });
	};

	useEffect(() => {
		setBreadcrumbText(dispatch, 'Credit Assessment Details');
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(approvalStatusData)) {
			const formattedData: SelectType[] | [] = approvalStatusData.map((item: any) => ({
				id: item.value,
				label: item.label,
				value: item.value,
			}));

			setApprovalStatusOptions(formattedData);
		}
	}, [approvalStatusData]);

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12}>
					<Box px={4}>
						{approvalStatusDataIsError && (
							<Alert severity="error" sx={{ mt: 2, mb: 3 }}>
								{approvalStatusDataError.message.length > 0
									? approvalStatusDataError.message
									: 'An error occurred while fetching the banks data'}
							</Alert>
						)}
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex', gap: 2 }}>
							<Typography sx={{ fontWeight: 600 }}>LOAN ID:</Typography>
							<Typography sx={{ fontWeight: 600 }} variant="body1">
								#1001
							</Typography>
						</Box>

						<Button>Return</Button>
					</Box>
				</Grid>

				<Grid item xs={12} sm={12} md={12}>
					<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
						<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
							<Typography
								variant="subtitle1"
								pb={1}
								sx={{ color: (theme) => theme.palette.primary.dark, fontWeight: 600 }}
							>
								Approval:
							</Typography>

							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="outlined-select-input-adornment-for-approval-status-label">
									<span>
										Approval Status
										<span style={{ color: 'red' }}> * </span>
									</span>
								</InputLabel>

								<Select
									disabled={approvalStatusDataIsLoading}
									labelId="outlined-select-input-adornment-for-approval-status-label"
									id="outlined-select-input-adornment-for-approval-status"
									label="Approval Status * "
									sx={{ width: 200 }}
									value={approvalStatus}
									onChange={(event) => {
										setApprovalStatus(event.target.value);
									}}
								>
									<MenuItem value="">NONE</MenuItem>

									{approvalStatusOptions?.map((item) => (
										<MenuItem key={item?.id} value={item?.value}>
											{item?.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>

						<Box sx={{ cursor: 'pointer' }}>
							<Button sx={{ p: 0 }}>
								<Lock />
							</Button>
						</Box>

						<Box sx={{ cursor: 'pointer' }}>
							<Button variant="contained" color="primary" sx={{ px: 4, py: 2 }}>
								Submit to bank
							</Button>
						</Box>
					</Box>
				</Grid>
			</Grid>

			<Box my={3.5} />

			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12}>
					<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
						<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="outlined-select-input-adornment-for-approval-status-label">
									<span>Selected Assessment Outcome</span>
								</InputLabel>

								<Select
									labelId="outlined-select-input-adornment-for-approval-status-label"
									id="outlined-select-input-adornment-for-approval-status"
									label="Selected Assessment Outcome"
									sx={{ width: 400 }}
									value={selectedCurrentState}
									onChange={handleChangeCurrentState}
								>
									{creditAssessmentDetailsOptions?.map((item) => (
										<MenuItem key={item?.id} value={item?.value}>
											{item?.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</Box>
				</Grid>
			</Grid>

			<Box my={5} />
			{selectedCurrentState === LOAN_TO_INCOME_RATIO && <LoanToIncomeRatioDetails />}
			{selectedCurrentState === DISPOSABLE_INCOME && <DisposableIncomeDetails />}
			{selectedCurrentState === LOSS_PROVISION && <LossProvisionDetails />}
			<Box my={5} />
		</>
	);
};

export default CreditAssessmentDetails;
