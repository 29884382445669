import { Box, Button, Grid, Typography } from '@mui/material';
import { type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { DISPOSABLE_INCOME, LOAN_TO_INCOME_RATIO, LOSS_PROVISION } from '../../constants/credit-assessment-options';
import { INCOME_TO_CREDIT_RATIO, LOSS_SHARE, SHARE_DISPOSAL_INCOME_BUFFER } from '../../constants/credit-constants';
import { type Data } from '../../data/loan-applications-data';
import theme from '../../theme/default-theme';

interface CreditAssessmentOutcomeInformationProps {
	data: Data | null;
}

const CreditAssessmentOutcomeInformation: FunctionComponent<CreditAssessmentOutcomeInformationProps> = ({ data }) => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
	const isIncomeToLoanRatioFit = parseFloat(data?.incomeToLoanRatio!) < INCOME_TO_CREDIT_RATIO;

	const isShareDisposableIncomeBufferFit =
		// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
		parseFloat(data?.shareDisposableIncomeBuffer!) >= SHARE_DISPOSAL_INCOME_BUFFER;

	// eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
	const isLossShareFit = parseFloat(data?.lossShare!) < LOSS_SHARE;

	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={12} mt={4}>
				<Box px={4}>
					<Box
						sx={{
							p: 1.5,
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
							border: `2px solid ${theme.palette.warning.main}`,
							borderRadius: 1,
						}}
					>
						<Typography sx={{}}>Credit Assessment Outcome</Typography>

						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
							<Box
								sx={{
									display: 'flex',
									gap: 5,
									color: isIncomeToLoanRatioFit ? 'green' : (theme) => theme.palette.warning.main,
								}}
							>
								<Typography sx={{ flex: 1 }}>Loan to Income Ratio:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.incomeToLoanRatio} (&lt; 6.0)</Typography>
								<Link
									style={{ textDecoration: 'none' }}
									to={`/credit-assessment/${data?.uuid}?state=${LOAN_TO_INCOME_RATIO}`}
								>
									<Button
										variant="contained"
										color={isIncomeToLoanRatioFit ? 'success' : 'warning'}
										size="small"
										sx={{
											'&:hover': {
												backgroundColor: (theme) =>
													isIncomeToLoanRatioFit ? theme.palette.success.main : theme.palette.warning.main,
											},
										}}
									>
										Explore
									</Button>
								</Link>
							</Box>

							<Box
								sx={{
									display: 'flex',
									gap: 5,
									color: isShareDisposableIncomeBufferFit ? 'green' : (theme) => theme.palette.warning.main,
								}}
							>
								<Typography sx={{ flex: 1 }}>Disposable Income Buffer:</Typography>
								<Typography sx={{ flex: 1 }}>{`${data?.shareDisposableIncomeBuffer}%`}</Typography>
								<Link
									style={{ textDecoration: 'none' }}
									to={`/credit-assessment/${data?.uuid}?state=${DISPOSABLE_INCOME}`}
								>
									<Button
										variant="contained"
										color={isShareDisposableIncomeBufferFit ? 'success' : 'warning'}
										size="small"
										sx={{
											'&:hover': {
												backgroundColor: (theme) =>
													isShareDisposableIncomeBufferFit ? theme.palette.success.main : theme.palette.warning.main,
											},
										}}
									>
										Explore
									</Button>
								</Link>
							</Box>

							<Box
								sx={{
									display: 'flex',
									gap: 5,
									color: isLossShareFit ? 'green' : (theme) => theme.palette.warning.main,
								}}
							>
								<Typography sx={{ flex: 1 }}>Loss Share:</Typography>
								<Typography sx={{ flex: 1 }}>0.049 (&lt; 0.05)</Typography>
								<Link
									style={{ textDecoration: 'none' }}
									to={`/credit-assessment/${data?.uuid}?state=${LOSS_PROVISION}`}
								>
									<Button
										variant="contained"
										color={isLossShareFit ? 'success' : 'warning'}
										size="small"
										sx={{
											'&:hover': {
												backgroundColor: (theme) =>
													isLossShareFit ? theme.palette.success.main : theme.palette.warning.main,
											},
										}}
									>
										Explore
									</Button>
								</Link>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Loan Documents</Typography>
								<Typography sx={{ flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
									<a
										href="https://securefiles.net/2342f2343"
										target="_blank"
										rel="noreferrer noopener"
										style={{
											color: '#000000',
										}}
									>
										https://securefiles.net/ourlegup/credit_assessment/2342f2343
									</a>
								</Typography>
								<Box sx={{ minWidth: '75px' }} />
							</Box>
						</Box>
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
};

export default CreditAssessmentOutcomeInformation;
