export interface LoanApplicantData {
	id: string;
	typeOfIncome: string;
	value: string;
	consideration: string;
	netValue: string;
}

export const loanApplicantData: LoanApplicantData[][] = [
	[
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b424',
			typeOfIncome: 'PAYG',
			value: '100000',
			consideration: '100%',
			netValue: '100000',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b425',
			typeOfIncome: 'Self Employed',
			value: '10000',
			consideration: '0%',
			netValue: '0',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b426',
			typeOfIncome: 'Casual / Freelance',
			value: '20000',
			consideration: '75%',
			netValue: '15000',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b427',
			typeOfIncome: 'Investment / Rental',
			value: '40000',
			consideration: '75%',
			netValue: '30000',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b6t8',
			typeOfIncome: 'Investment / Rental',
			value: '',
			consideration: '',
			netValue: '30000',
		},
	],
	[
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b6t4',
			typeOfIncome: 'PAYG',
			value: '100000',
			consideration: '100%',
			netValue: '100000',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b6t5',
			typeOfIncome: 'Self Employed',
			value: '10000',
			consideration: '0%',
			netValue: '0',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b6t6',
			typeOfIncome: 'Casual / Freelance',
			value: '20000',
			consideration: '75%',
			netValue: '15000',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b6t7',
			typeOfIncome: 'Investment / Rental',
			value: '40000',
			consideration: '75%',
			netValue: '30000',
		},
		{
			id: '87fd8755-0a67-4f28-b765-e713dc94b6t8',
			typeOfIncome: 'Investment / Rental',
			value: '',
			consideration: '',
			netValue: '30000',
		},
	],
];
