import { type UpdateOutcomeByLoanIdParams } from '../../../types/Outcomes';
import api from '../../config/instance';

const updateOutcomeByLoanIdMutation = async (params: UpdateOutcomeByLoanIdParams) => {
	const graphqlMutation = {
		query: `
	    mutation update_credit_assessment_assessment_outcomes (
        $loan_id: uuid!,
        $approve_loan: Boolean!,
        $approval_status: String,
        $updated_at: timestamptz!
      ) {
        update_credit_assessment_assessment_outcomes (
          where: { loan_id: {_eq: $loan_id }},
          _set: {
            updated_at: $updated_at,
            approve_loan: $approve_loan,
            approval_status: $approval_status
          }
        ) {
          returning {
            id
            approve_loan
            approval_status
            updated_at
          }
        }
      }
	  `,
		variables: {
			loan_id: String(params.loanId),
			approve_loan: Boolean(params.approval),
			approval_status: String(params.approvalStatus),
			updated_at: params.updatedAt,
		},
	};

	try {
		const response = await api.post('/', graphqlMutation);

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (response.status === 200 && response.data?.data?.update_credit_assessment_assessment_outcomes?.returning) {
			return await Promise.resolve(response.data?.data?.update_credit_assessment_assessment_outcomes?.returning);
		}

		return await Promise.reject(response.data?.errors);
	} catch (error: any) {
		console.log(error);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		throw new Error(String(error?.[0]?.message || error?.message || error));
	}
};

export default updateOutcomeByLoanIdMutation;
