import { type Select } from '../types/Select';

export const loanApplicationBankOptions: Select[] = [
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde3',
		label: 'Bank Partner 1',
		value: 1,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde4',
		label: 'Bank Partner 2',
		value: 2,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde5',
		label: 'Bank Partner 3',
		value: 3,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde6',
		label: 'Bank Partner 4',
		value: 4,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde7',
		label: 'Bank Partner 5',
		value: 5,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde8',
		label: 'Bank Partner 6',
		value: 6,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde9',
		label: 'Bank Partner 7',
		value: 7,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04eddea',
		label: 'Bank Partner 8',
		value: 8,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04eddeb',
		label: 'Bank Partner 9',
		value: 9,
	},
];

export const loanApplicationDateOptions: Select[] = [
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde3',
		label: 'Today',
		value: 1,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde4',
		label: 'Yesterday',
		value: 2,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde5',
		label: 'This Week',
		value: 3,
	},
	{
		id: 'e8e08b49-8d04-43a4-a403-aa45a04edde6',
		label: 'Last Week',
		value: 4,
	},
];
