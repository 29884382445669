import api from '../../config/instance';

const banksQuery = async () => {
	const graphqlQuery = {
		query: `
	    query credit_assessment_banks {
          credit_assessment_banks {
            id
            bank_id
            bank_name
          }
        }
	  `,
	};

	try {
		const response = await api.post('/', graphqlQuery);

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (response.status === 200 && response.data?.data?.credit_assessment_banks) {
			return await Promise.resolve(response.data?.data?.credit_assessment_banks);
		}

		return await Promise.reject(response.data?.errors);
	} catch (error: any) {
		console.log(error);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		throw new Error(String(error?.[0]?.message || error?.message || error));
	}
};

export default banksQuery;
