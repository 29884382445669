const Submitted = () => {
	return (
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="12" height="12" rx="3" fill="#000032" />
			<path
				d="M6.47406 9.55058L8.91386 3.44986C8.9392 3.38691 8.94547 3.3179 8.9319 3.25142C8.91833 3.18493 8.88551 3.1239 8.83753 3.07592C8.78955 3.02793 8.72852 2.99512 8.66203 2.98155C8.59554 2.96797 8.52653 2.97425 8.46358 2.99959L2.36287 5.43938C2.30976 5.46023 2.26268 5.49397 2.22588 5.53756C2.18907 5.58115 2.1637 5.63322 2.15204 5.68906C2.14039 5.74491 2.14282 5.80278 2.15912 5.85745C2.17542 5.91212 2.20507 5.96188 2.2454 6.00223L3.37109 7.13281C3.49344 7.25516 3.68921 7.26985 3.82625 7.16217L7.74657 4.16687L4.74883 8.08474C4.6436 8.22423 4.65828 8.42 4.78064 8.54236L5.91122 9.66804C6.08496 9.84179 6.38352 9.78306 6.47406 9.55058Z"
				fill="#C8EC82"
			/>
		</svg>
	);
};

export default Submitted;
