import { type FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';

import type RouteType from '../types/Route';

interface RouteConfigPropsTypes {
	routes: RouteType[];
}

const RouteConfig: FunctionComponent<RouteConfigPropsTypes> = ({ routes = [] }) => {
	return (
		<Routes>
			{routes.map((route) => (
				<Route key={route.id} path={route.path} element={<route.component />} />
			))}
		</Routes>
	);
};

export default RouteConfig;
