import { MoreVert } from '@mui/icons-material';
import { IconButton, Toolbar, Tooltip, Typography, alpha } from '@mui/material';

interface SelectedItemsTableToolbarProps {
	numSelected: number;
}

const SelectedItemsTableToolbar = (props: SelectedItemsTableToolbarProps) => {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : null}

			{numSelected > 0 ? (
				<Tooltip title="Options">
					<IconButton>
						<MoreVert />
					</IconButton>
				</Tooltip>
			) : null}
		</Toolbar>
	);
};

export default SelectedItemsTableToolbar;
