import { createContext } from 'react';

import initialState from '../states/breadcrumbState';
import { type Context } from '../types/Breadcrumb';

const contextInitialState: Context = {
	state: initialState,
	dispatch: () => {},
};

const BreadcrumbContext = createContext<Context>(contextInitialState);

export default BreadcrumbContext;
