import { Box, Grid, Typography } from '@mui/material';

import { loanApplicantData, type LoanApplicantData } from '../../data/loan-applicant-data';
import ApplicantIncomeTable from '../organisms/ApplicantIncomeTable';

const LoanToIncomeRatioDetails = () => {
	return (
		<>
			<Grid container spacing={3}>
				{loanApplicantData?.map((applicantData: LoanApplicantData[], index: number) => (
					<Grid key={index} item xs={12} sm={12} md={6}>
						<Box>
							<Typography variant="h5" mb={2} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
								Applicant {index + 1}
							</Typography>

							<ApplicantIncomeTable />
						</Box>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default LoanToIncomeRatioDetails;
