import { Add } from '@mui/icons-material';
import { Alert, Box, Card, Grid, IconButton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import banksQuery from '../../apis/graphql/query/banks-query';
import loanPropertyApplicantOutcomeQuery from '../../apis/graphql/query/loan-property-applicant-outcome-query';
import SelectInputField from '../../components/molecules/SelectInputField';
import TransactionLogTable from '../../components/templates/TransactionLogTable';
import { loanApplicationDateOptions } from '../../constants/loan-applications';
import { type Data } from '../../data/loan-applications-data';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { setBreadcrumbText } from '../../shared';
import { type BankOptionType } from '../../types/Bank';
import { convertSpaceToUnderscoreThenUppercase } from '../../utils/convert-string-to-kebab-case';
import formatLoansData from '../../utils/format-loans-data';
import isEmpty from '../../utils/is-empty';

const CreditAssessment = () => {
	const [banksOptions, setBanksOptions] = useState<BankOptionType[] | []>([]);
	const [loansListData, setLoansListData] = useState<Data[]>([]);
	const { dispatch } = useBreadcrumb();

	const {
		data: banksData,
		isLoading: banksDataIsLoading,
		isError: banksDataIsError,
		error: banksDataError,
	} = useQuery({
		queryKey: ['credit_assessment_banks'],
		queryFn: async () => await banksQuery(),
	});

	const {
		data: loansData,
		isLoading: loansDataIsLoading,
		isError: loansDataIsError,
		error: loansDataError,
	} = useQuery({
		queryKey: ['credit_assessment_loan-property-applicant-outcome'],
		queryFn: async () => await loanPropertyApplicantOutcomeQuery(),
	});

	const handleSubmitQuery = (values: any) => {};

	useEffect(() => {
		setBreadcrumbText(dispatch, 'Credit Assessment');
	}, [dispatch]);

	useEffect(() => {
		if (!isEmpty(banksData)) {
			const formattedData: BankOptionType[] | [] = banksData.map((item: any) => ({
				id: item.id,
				label: item.bank_name,
				value: convertSpaceToUnderscoreThenUppercase(item?.bank_name as string),
			}));

			setBanksOptions(formattedData);
		}
	}, [banksData]);

	useEffect(() => {
		if (!isEmpty(loansData)) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			const formattedLoansData: Data[] = formatLoansData(loansData);
			setLoansListData(formattedLoansData);
		}
	}, [loansData]);

	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={12} md={12}>
					{banksDataIsError && (
						<Alert severity="error" sx={{ mt: 2, mb: 3 }}>
							{banksDataError.message.length > 0
								? banksDataError.message
								: 'An error occurred while fetching the banks data'}
						</Alert>
					)}

					{loansDataIsError && (
						<Alert severity="error" sx={{ mt: 2, mb: 3 }}>
							{loansDataError.message.length > 0
								? loansDataError.message
								: 'An error occurred while fetching the loans data'}
						</Alert>
					)}

					<Typography variant="h5" mb={2} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
						Loan Applications
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={12}>
					<Formik initialValues={{}} onSubmit={handleSubmitQuery}>
						<fieldset style={{ border: 'none' }}>
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={3}>
										<SelectInputField
											isRequired
											fullWidth
											disabled={banksDataIsLoading}
											type="select"
											name="bank"
											label="Select Bank"
											defaultValue={'BANK_OF_AUSTRALIA'}
											items={banksOptions}
											sx={{
												background: (theme) => theme.palette.secondary.main,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={3}>
										<SelectInputField
											isRequired
											fullWidth
											type="select"
											name="date"
											label="Choose Date"
											defaultValue={1}
											items={loanApplicationDateOptions}
											sx={{
												background: (theme) => theme.palette.secondary.main,
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={12} md={3}>
										<IconButton
											aria-label="Add more"
											size="large"
											sx={{
												marginTop: '3px',
												borderRadius: '0px',
												background: (theme) => theme.palette.secondary.main,
											}}
										>
											<Add />
										</IconButton>
									</Grid>
								</Grid>
							</Form>
						</fieldset>
					</Formik>
				</Grid>

				<Grid container mt={4}>
					<Grid item xs={12} sm={12} md={12}>
						<Card
							variant="outlined"
							sx={{
								px: { xs: 1.5, sm: 2, md: 4 },
								py: { xs: 4, sm: 4, md: 4 },
								border: 'none',
							}}
						>
							<Box sx={{ minHeight: '70vh' }}>
								<TransactionLogTable data={loansListData} loading={loansDataIsLoading} />
							</Box>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default CreditAssessment;
