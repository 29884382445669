import api from '../../config/instance';

const loanPropertyApplicantOutcomeQuery = async () => {
	const graphqlQuery = {
		query: `
	    query credit_assessment_loans {
          credit_assessment_loans {
            id
            loan_type
            application_type
            loan_balance
            property_valuation
            valuation_date
            term_of_loan
            number_of_applicants
            olu_loan_duration
            original_loan_size
            private_health_insurance
            loan_documents
            interest_rate
            existing_loan
            bank_id
            updated_by
            created_at
            updated_at
            applicants {
              id
              age
              female
              payg_income
              other_income
              occupation
              occupation_description
              industry
              industry_description
              duration_of_employment
              employment_casual
              public_sector
              created_at
              updated_at
            }
            assessment_outcomes {
              id
              approve_loan
              approval_status
              accumulated_principle
              buffer_months
              loan_to_income_ratio
              loss_share
              payment
              share_disposal_income_buffer
              share_gross_income_buffer
              total_loan_ratio
              total_loss
              total_net_fees
              loan_documents
              loan_id
              create_at
              updated_at
            }
            properties {
              id
              address
              lga
              postcode
              property_type
              sale
              state
              strata_title
              suburb
              created_at
              updated_at
            }
            notes {
              id
              note
              note_by
              created_at
              updated_at
            }
          }
        }
	  `,
	};

	try {
		const response = await api.post('/', graphqlQuery);

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (response.status === 200 && response.data?.data?.credit_assessment_loans) {
			return await Promise.resolve(response.data?.data?.credit_assessment_loans);
		}

		return await Promise.reject(response.data?.errors);
	} catch (error: any) {
		console.log(error);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		throw new Error(String(error?.[0]?.message || error?.message || error));
	}
};

export default loanPropertyApplicantOutcomeQuery;
