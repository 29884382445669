import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

const EmploymentApplicantsTable = () => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
				<TableHead>
					<TableRow
						hover
						sx={{
							background: (theme) => theme.palette.secondary.light,
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
							Applicant
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Employment Type
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Industry
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Role
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center"></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Applicant 1
						</TableCell>
						<TableCell align="center">Tertiary Education Teachers</TableCell>
						<TableCell align="center">Tertiary Education</TableCell>
						<TableCell align="center">Educational Teacher</TableCell>
						<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
							<TableHead>
								<TableRow hover>
									<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
										Salary
									</TableCell>
									<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
										Year
									</TableCell>
									<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
										Growth
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell align="left">{formatNumberToCurrency(100000)}</TableCell>
									<TableCell align="center">2020</TableCell>
									<TableCell align="center">5%</TableCell>
								</TableRow>

								<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell align="left">{formatNumberToCurrency(105000)}</TableCell>
									<TableCell align="center">2021</TableCell>
									<TableCell align="center">5%</TableCell>
								</TableRow>

								<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell align="left">{formatNumberToCurrency(110000)}</TableCell>
									<TableCell align="center">2022</TableCell>
									<TableCell align="center">5%</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableRow>

					<TableRow
						hover
						sx={{ border: '1px solid rgb(224 224 224)', '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">Aggregate CAGR for Applicant 1:</TableCell>
						<TableCell align="center">9.5%</TableCell>
					</TableRow>

					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Applicant 2
						</TableCell>
						<TableCell align="center">Tertiary Education Teachers</TableCell>
						<TableCell align="center">Tertiary Education</TableCell>
						<TableCell align="center">Educational Teacher</TableCell>
						<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
							<TableHead>
								<TableRow hover>
									<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
										Salary
									</TableCell>
									<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
										Year
									</TableCell>
									<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
										Growth
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell align="left">{formatNumberToCurrency(110000)}</TableCell>
									<TableCell align="center">2020</TableCell>
									<TableCell align="center">7%</TableCell>
								</TableRow>

								<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell align="left">{formatNumberToCurrency(115000)}</TableCell>
									<TableCell align="center">2021</TableCell>
									<TableCell align="center">15%</TableCell>
								</TableRow>

								<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell align="left">{formatNumberToCurrency(120000)}</TableCell>
									<TableCell align="center">2022</TableCell>
									<TableCell align="center">15%</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableRow>

					<TableRow
						hover
						sx={{ border: '1px solid rgb(224 224 224)', '&:last-child td, &:last-child th': { border: 0 } }}
					>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">Aggregate CAGR for Applicant 2:</TableCell>
						<TableCell align="center">10.5%</TableCell>
					</TableRow>

					<TableRow
						hover
						sx={{
							border: '1px solid rgb(224 224 224)',
							// background: (theme) => theme.palette.success.main,
							background: '#EAFFC0',
							'&:last-child td, &:last-child th': { border: 0 },
						}}
					>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center"></TableCell>
						<TableCell align="center">Combined Aggregate CAGR (compound annual growth rate): </TableCell>
						<TableCell align="center">15%</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default EmploymentApplicantsTable;
