import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { type FunctionComponent } from 'react';

import { type Data } from '../../data/loan-applications-data';
import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

interface CreditAssessmentLoanInformationProps {
	data: Data | null;
}

const CreditAssessmentLoanInformation: FunctionComponent<CreditAssessmentLoanInformationProps> = ({ data }) => {
	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={12} mt={4}>
				<Box px={4}>
					<Accordion component={Box} defaultExpanded>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="loan-information-content"
							id="loan-information-header"
						>
							<Typography>Loan Information</Typography>
						</AccordionSummary>

						<AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Original Loan:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.originalLoan?.toUpperCase() === 'TRUE' ? 'Yes' : 'N/A'}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Loan Balance:</Typography>
								<Typography sx={{ flex: 1 }}>
									{data?.loanBalance != null ? formatNumberToCurrency(parseInt(data?.loanBalance, 10)) : ''}
								</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Interest Rate:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.interestRate}%</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Number of Applicants:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.numberOfApplicants}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Property Valuation:</Typography>
								<Typography sx={{ flex: 1 }}>
									{data?.propertyValuation != null ? formatNumberToCurrency(parseInt(data?.propertyValuation, 10)) : ''}
								</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Property Type:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.propertyType}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Strata Title:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.strataTitle?.toUpperCase() === 'TRUE' ? 'Yes' : 'No'}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Address:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.propertyAddress}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Suburb:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.suburb}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>State:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.state}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Post Code:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.postcode}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>LGA:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.lga}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Sale:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.sale?.toUpperCase() === 'TRUE' ? 'Yes' : 'No'}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Valuation Date:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.valuationDate}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Term of Loan:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.termOfLoan}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>OLU Loan Duration (est.):</Typography>
								<Typography sx={{ flex: 1 }}>{data?.oluLoanDuration}</Typography>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
			</Grid>
		</Grid>
	);
};

export default CreditAssessmentLoanInformation;
