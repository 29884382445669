import { Assessment } from '@mui/icons-material';

import type Route from '../types/Route';

interface SidebarRoute {
	id: string;
	title: string;
	items: Route[];
}

export const sidebarRoutes: SidebarRoute[] = [
	{
		id: 'eff3b49c-2da4-4rfe-asdf-0242ac150001',
		title: '',
		items: [
			// {
			// 	id: 'eff3b49c-2da4-4rfe-asdf-0242ac150011',
			// 	name: 'Dashboard',
			// 	path: '/dashboard',
			// 	icon: Dashboard,
			// 	exact: true,
			// 	protected: false,
			// 	component: () => null,
			// },
			{
				id: 'eff3b49c-2da4-4rfe-asdf-0242ac150012',
				name: 'Credit Assessment',
				path: '/credit-assessment',
				icon: Assessment,
				exact: true,
				protected: false,
				component: () => null,
			},
			// {
			// 	id: 'eff3b49c-2da4-4rfe-asdf-0242ac150013',
			// 	name: 'Model Parameters',
			// 	path: '/model-parameters',
			// 	icon: AccountTree,
			// 	exact: true,
			// 	protected: false,
			// 	component: () => null,
			// },
		],
	},
];
