// eslint-disable-next-line prettier/prettier
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import ChevronUp from '../../assets/svgs/ChevronUp';
import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

const HEMLgaTable = () => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table aria-label="applicant income table">
					<TableHead>
						<TableRow>
							<TableCell
								sx={{
									background: (theme) => theme.palette.secondary.light,
									color: (theme) => theme.palette.secondary.dark,
									textTransform: 'uppercase',
									fontWeight: 400,
								}}
								align="left"
							>
								LGA
							</TableCell>
							<TableCell
								sx={{
									background: (theme) => theme.palette.secondary.main,
									fontWeight: 400,
								}}
								align="center"
							>
								{formatNumberToCurrency(100000)}
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								sx={{
									background: (theme) => theme.palette.secondary.light,
									color: (theme) => theme.palette.secondary.dark,
									textTransform: 'uppercase',
								}}
								component="th"
								scope="row"
							>
								Dual income no kids
							</TableCell>
							<TableCell
								sx={{
									// background: (theme) => theme.palette.success.main,
									background: '#EAFFC0',
								}}
								align="center"
							>
								<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Typography variant="body2">{formatNumberToCurrency(100000)}</Typography>
									<Box>
										<ChevronUp />
									</Box>
								</Box>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default HEMLgaTable;
