import { type Select } from '../types/Select';

export const APPLICANT1_INCOME_PROBABILITY_RATIO = 'APPLICANT1';
export const APPLICANT2_INCOME_PROBABILITY_RATIO = 'APPLICANT2';

export const incomeProbabilityRationOptions: Select[] = [
	{
		id: '865a1392-3efd-4281-a745-c09ccd9ddef1',
		label: 'Applicant 1',
		value: APPLICANT1_INCOME_PROBABILITY_RATIO,
	},
	{
		id: '865a1392-3efd-4281-a745-c09ccd9ddef2',
		label: 'Applicant 2',
		value: APPLICANT2_INCOME_PROBABILITY_RATIO,
	},
];
