// eslint-disable-next-line prettier/prettier
import { Button, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Form, Formik, type FormikProps, type FormikValues } from 'formik';
import { useReducer, useRef, type FunctionComponent } from 'react';

import { approvalOptions, approvalStatusOptions } from '../../constants/approval-status';
import initialState from '../../pages/credit-assessment/validations/initial-state';
import validationSchema from '../../pages/credit-assessment/validations/validation-schema';
import { defaultTheme } from '../../theme/default-theme';
import Modal from '../molecules/Modal';
import SelectInputField from '../molecules/SelectInputField';
import TextInputField from '../molecules/TextInputField';
import TextareaInputField from '../molecules/TextareaInputField';

interface SubmitToBankFormProps {
	toggleDrawer: (event: any) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FormValues {}

const SubmitToBankForm: FunctionComponent<SubmitToBankFormProps> = ({ toggleDrawer = null }) => {
	const [isOpen, toggleModal] = useReducer((state: boolean) => !state, false);
	const submitRef = useRef<FormikProps<FormValues>>(null);

	const handleSubmit = (values: FormikValues) => {};

	const handleSubmitToBankForm = () => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (submitRef?.current?.handleSubmit) {
			submitRef?.current?.handleSubmit();
		}
	};

	const handleCloseDetailsModal = (event: any) => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (toggleDrawer) {
			toggleDrawer(event);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialState}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={submitRef}
			>
				<fieldset style={{ border: 'none' }}>
					<Form>
						<Box
							sx={{
								border: `1px solid ${defaultTheme.primary.main}`,
								marginTop: 4,
								borderRadius: '5px',
								p: 4,
								display: 'flex',
								flexDirection: 'column',
								gap: 3,
							}}
						>
							<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<Grid item xs={3} sm={3} md={3}>
									<Typography sx={{ fontWeight: 'semibold' }}>
										Approval <span style={{ color: defaultTheme.warning.main }}>*</span>
									</Typography>
								</Grid>

								<Grid item xs={9} sm={9} md={9}>
									<SelectInputField fullWidth name="approval" title="" label="Approval" items={approvalOptions} />
								</Grid>
							</Grid>

							<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<Grid item xs={3} sm={3} md={3}>
									<Typography sx={{ fontWeight: 'semibold' }}>
										Approval status <span style={{ color: defaultTheme.warning.main }}>*</span>
									</Typography>
								</Grid>

								<Grid item xs={9} sm={9} md={9}>
									<SelectInputField
										fullWidth
										name="approvalStatus"
										title=""
										label="Approval status"
										items={approvalStatusOptions}
									/>
								</Grid>
							</Grid>

							<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<Grid item xs={3} sm={3} md={3}>
									<Typography sx={{ fontWeight: 'semibold' }}>
										Approval date <span style={{ color: defaultTheme.warning.main }}>*</span>
									</Typography>
								</Grid>

								<Grid item xs={9} sm={9} md={9}>
									<TextInputField fullWidth type="date" name="approvalDate" title="" label="" />
								</Grid>
							</Grid>

							<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
								<Grid item xs={3} sm={3} md={3}>
									<Typography sx={{ fontWeight: 'semibold' }}>
										Note <span style={{ color: defaultTheme.warning.main }}>*</span>
									</Typography>
								</Grid>

								<Grid item xs={9} sm={9} md={9}>
									<TextareaInputField
										fullWidth
										name="note"
										title=""
										titleStyle={{ color: defaultTheme.primary.dark }}
										label="Some label"
										placeholder="Write a note"
										inputStyle={{
											display: 'inline-block',
											width: '100%',
											border: `1px solid ${defaultTheme.secondary.contrastText}`,
											borderRadius: '5px',
											padding: '5px',
											fontSize: '16px',
										}}
									/>
								</Grid>
							</Grid>

							<Box sx={{ display: 'flex', gap: 2 }}>
								<Button
									type="button"
									variant="contained"
									onClick={toggleModal}
									color="success"
									sx={{ '&:hover': { background: (theme) => theme.palette.success.main } }}
								>
									Submit to bank
								</Button>

								<Button
									type="button"
									variant="outlined"
									sx={{ border: '1px solid transparent' }}
									onClick={handleCloseDetailsModal}
								>
									Cancel
								</Button>
							</Box>
						</Box>
					</Form>
				</fieldset>
			</Formik>

			<Modal open={isOpen} close={toggleModal}>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
					<Typography variant="h5" mb={3} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
						Are you sure you want to submit?
					</Typography>

					<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3 }}>
						<Button
							type="button"
							variant="contained"
							color="success"
							sx={{ px: 5, '&:hover': { background: (theme) => theme.palette.success.main } }}
							onClick={handleSubmitToBankForm}
						>
							Yes
						</Button>

						<Button
							type="button"
							variant="contained"
							color="warning"
							sx={{ px: 5, '&:hover': { background: (theme) => theme.palette.warning.main } }}
							onClick={toggleModal}
						>
							No
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default SubmitToBankForm;
