const Assigned = () => {
	return (
		<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" width="12" height="12" rx="3" fill="#FFBEFF" />
			<path
				d="M9.46247 5.32828L9.73124 5.61671L10 5.32828L9.73124 5.03984L9.46247 5.32828ZM3 8.59203C3 8.70023 3.04005 8.804 3.11134 8.88051C3.18263 8.95702 3.27932 9 3.38015 9C3.48097 9 3.57766 8.95702 3.64895 8.88051C3.72024 8.804 3.76029 8.70023 3.76029 8.59203H3ZM7.83051 7.65656L9.73124 5.61671L9.19371 5.03984L7.29298 7.07969L7.83051 7.65656ZM9.73124 5.03984L7.83051 3L7.29298 3.57687L9.19371 5.61671L9.73124 5.03984ZM9.46247 4.92031H5.66102V5.73625H9.46247V4.92031ZM3 7.77609V8.59203H3.76029V7.77609H3ZM5.66102 4.92031C4.95527 4.92031 4.27843 5.22119 3.77939 5.75675C3.28036 6.29231 3 7.01869 3 7.77609H3.76029C3.76029 7.23509 3.96055 6.71625 4.317 6.3337C4.67346 5.95116 5.15691 5.73625 5.66102 5.73625V4.92031Z"
				fill="#000032"
			/>
		</svg>
	);
};

export default Assigned;
