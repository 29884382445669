/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import DoneIcon from '@mui/icons-material/Done';
// eslint-disable-next-line prettier/prettier
import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography, type OutlinedInputProps, type SxProps } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useState, type ComponentProps, type FunctionComponent } from 'react';

import convertStringToKebabCase from '../../utils/convert-string-to-kebab-case';
import InputHelperText from '../atoms/InputHelperText';

type TextInputFieldPropsTypes = ComponentProps<'input'> &
	OutlinedInputProps & {
		label: string;
		title?: string;
		boxStyle?: SxProps;
		titleStyle?: SxProps;
		inputStyle?: SxProps;
		rows?: number;
		isRequired?: boolean;
		fullWidth?: boolean;
		successValidation?: boolean;
		validation?: any;
	};

const TextInputField: FunctionComponent<TextInputFieldPropsTypes> = ({
	boxStyle,
	inputStyle,
	titleStyle,
	label = '',
	title = '',
	rows = 1,
	isRequired = false,
	fullWidth = false,
	successValidation = false,
	validation = null,
	...props
}) => {
	const [isValid, setIsValid] = useState(false);
	const [field, meta] = useField(props as string);
	const convertLabelStringToTestidPrefix = convertStringToKebabCase(label);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (validation) {
			validation
				.validate(field.value)
				.then(() => {
					setIsValid(true);
				})
				.catch(() => {
					setIsValid(false);
				});
		}
	}, [field.value, validation]);

	return (
		<Box sx={boxStyle} data-testid={`${convertLabelStringToTestidPrefix}-input-box`}>
			{title.length > 0 && (
				<Typography variant="subtitle1" pb={1} sx={{ color: (theme) => `${theme.palette.secondary.dark}` }}>
					{title}
				</Typography>
			)}

			<FormControl
				variant="outlined"
				fullWidth={fullWidth}
				error={!(meta?.error == null) && !!meta?.touched}
				data-testid={`${convertLabelStringToTestidPrefix}-input-control`}
			>
				<InputLabel
					htmlFor={`outlined-adornment-${label}`}
					data-testid={`${convertLabelStringToTestidPrefix}-input-label`}
				>
					<span>
						{label}
						{isRequired && Boolean(label) && <span style={{ color: 'red' }}> * </span>}
					</span>
				</InputLabel>

				<OutlinedInput
					id={`outlined-adornment-${label}`}
					label={label}
					sx={inputStyle}
					minRows={rows}
					data-testid={`${convertLabelStringToTestidPrefix}-input`}
					endAdornment={
						successValidation &&
						isValid && (
							<InputAdornment position="end">
								<DoneIcon color="success" />
							</InputAdornment>
						)
					}
					{...props}
					{...field}
				/>

				{isRequired && meta?.error != null && meta?.touched && (
					<InputHelperText error={meta.error} testid={`${convertLabelStringToTestidPrefix}-input-error-text`} />
				)}
			</FormControl>
		</Box>
	);
};

export default TextInputField;
