// eslint-disable-next-line prettier/prettier
import { Box, FormControl, TextareaAutosize, Typography, type OutlinedInputProps, type SxProps } from '@mui/material';
import { useField } from 'formik';
import { type CSSProperties, type ComponentProps, type FunctionComponent } from 'react';

import convertStringToKebabCase from '../../utils/convert-string-to-kebab-case';
import InputHelperText from '../atoms/InputHelperText';

type TextareaInputFieldPropsTypes = ComponentProps<'textarea'> &
	OutlinedInputProps & {
		label: string;
		title?: string;
		boxStyle?: SxProps;
		titleStyle?: SxProps;
		inputStyle?: CSSProperties;
		rows?: number;
		isRequired?: boolean;
		fullWidth?: boolean;
	};

const TextareaInputField: FunctionComponent<TextareaInputFieldPropsTypes> = ({
	boxStyle,
	inputStyle,
	titleStyle,
	label = 'Enter value',
	title = '',
	rows = 4,
	isRequired = false,
	fullWidth = false,
	placeholder = '',
	...props
}) => {
	const [field, meta] = useField({ ...props, name: props.name! });
	const convertLabelStringToTestidPrefix = convertStringToKebabCase(label);

	return (
		<>
			<Box sx={boxStyle} data-testid={`${convertLabelStringToTestidPrefix}-text-area-input-box`}>
				{title.length > 0 && (
					<Typography
						variant="subtitle1"
						pb={1}
						sx={{ color: (theme) => `${theme.palette.secondary.dark}`, ...titleStyle }}
					>
						{title}
					</Typography>
				)}

				<FormControl
					variant="outlined"
					fullWidth={fullWidth}
					error={!(meta?.error == null) && !!meta?.touched}
					data-testid={`${convertLabelStringToTestidPrefix}-text-area-input-control`}
				>
					<TextareaAutosize
						style={{ fontFamily: 'Inter var', ...inputStyle }}
						id={`textarea-outlined-adornment-${label}`}
						minRows={rows}
						data-testid={`${convertLabelStringToTestidPrefix}-input`}
						placeholder={placeholder}
						// {...props}
						{...field}
					/>

					{isRequired && meta?.error != null && meta?.touched && (
						<InputHelperText
							error={meta.error}
							testid={`${convertLabelStringToTestidPrefix}-text-area-input-error-text`}
						/>
					)}
				</FormControl>
			</Box>
		</>
	);
};

export default TextareaInputField;
