import { Box, Modal as MUIModal } from '@mui/material';
import { type FunctionComponent, type PropsWithChildren } from 'react';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 350,
	maxWidth: 700,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: { xs: 2, sm: 3, md: 6.5 },
	borderRadius: '10px',
};

interface ModalPropsTypes {
	open: boolean;
	close: () => void;
}

const Modal: FunctionComponent<PropsWithChildren<ModalPropsTypes>> = ({ children, open = false, close }) => {
	return (
		<Box>
			<MUIModal
				open={open}
				onClose={close}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>{children}</Box>
			</MUIModal>
		</Box>
	);
};

export default Modal;
