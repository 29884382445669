import { Box, Typography } from '@mui/material';

const Copyright = () => {
	const currentYear = new Date().getFullYear();

	return (
		<Box px={2}>
			<Typography color={'white'}>&copy; {currentYear} all rights reserved.</Typography>
		</Box>
	);
};

export default Copyright;
