import { type Note } from '../../../types/Notes';
import api from '../../config/instance';

const notesMutation = async (params: Note) => {
	const variables: Note = params;

	const graphqlMutation = {
		query: `
	    mutation insert_credit_assessment_notes_one($object: credit_assessment_notes_insert_input!) {
          insert_credit_assessment_notes_one(object: $object) {
            id
            loan_id
            note
            note_by
            created_at
            updated_at
          }
        }
	  `,
		variables: {
			object: variables,
		},
	};

	try {
		const response = await api.post('/', graphqlMutation);

		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (response.status === 200 && response.data?.data?.insert_credit_assessment_notes_one) {
			return await Promise.resolve(response.data?.data?.insert_credit_assessment_notes_one);
		}

		return await Promise.reject(response.data?.errors);
	} catch (error: any) {
		console.log(error);
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		throw new Error(String(error?.[0]?.message || error?.message || error));
	}
};

export default notesMutation;
