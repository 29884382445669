import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const EmploymentProbabilityRatioTable = () => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
					<TableHead>
						<TableRow
							hover
							sx={{
								background: (theme) => theme.palette.secondary.light,
								color: (theme) => theme.palette.secondary.dark,
							}}
						>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left"></TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								Month
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								1
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								2
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								3
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								4
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								5
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								6
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								7
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								8
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								9
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								10
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								11
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								12
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								Value
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								align="center"
								sx={{
									textTransform: 'uppercase',
									fontWeight: 400,
									background: (theme) => theme.palette.secondary.light,
								}}
							>
								Month
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center">50%</TableCell>
							<TableCell align="center">49%</TableCell>
							<TableCell align="center">48%</TableCell>
							<TableCell align="center">47%</TableCell>
							<TableCell align="center">46%</TableCell>
							<TableCell align="center">45%</TableCell>
							<TableCell align="center">44%</TableCell>
							<TableCell align="center">43%</TableCell>
							<TableCell align="center">42%</TableCell>
							<TableCell align="center">41%</TableCell>
							<TableCell align="center">40%</TableCell>
							<TableCell align="center">39%</TableCell>
							<TableCell align="center"></TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								align="center"
								sx={{
									textTransform: 'uppercase',
									fontWeight: 400,
									background: (theme) => theme.palette.secondary.light,
								}}
							>
								1
							</TableCell>
							<TableCell align="center">0.020%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.008%</TableCell>
							<TableCell align="center">0.008%</TableCell>
							<TableCell align="center">0.008%</TableCell>
							<TableCell align="center">0.008%</TableCell>
							<TableCell align="center">0.009%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								align="center"
								sx={{
									textTransform: 'uppercase',
									fontWeight: 400,
									background: (theme) => theme.palette.secondary.light,
								}}
							>
								2
							</TableCell>
							<TableCell align="center">0.022%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.010%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.009%</TableCell>
							<TableCell align="center">0.010%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								align="center"
								sx={{
									textTransform: 'uppercase',
									fontWeight: 400,
									background: (theme) => theme.palette.secondary.light,
								}}
							>
								3
							</TableCell>
							<TableCell align="center">0.030%</TableCell>
							<TableCell align="center">0.015%</TableCell>
							<TableCell align="center">0.015%</TableCell>
							<TableCell align="center">0.014%</TableCell>
							<TableCell align="center">0.014%</TableCell>
							<TableCell align="center">0.014%</TableCell>
							<TableCell align="center">0.014%</TableCell>
							<TableCell align="center">0.013%</TableCell>
							<TableCell align="center">0.013%</TableCell>
							<TableCell align="center">0.013%</TableCell>
							<TableCell align="center">0.012%</TableCell>
							<TableCell align="center">0.012%</TableCell>
							<TableCell align="center">0.012%</TableCell>
							<TableCell align="center">0.013%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								align="center"
								sx={{
									textTransform: 'uppercase',
									fontWeight: 400,
									background: (theme) => theme.palette.secondary.light,
								}}
							>
								4
							</TableCell>
							<TableCell align="center">0.034%</TableCell>
							<TableCell align="center">0.017%</TableCell>
							<TableCell align="center">0.017%</TableCell>
							<TableCell align="center">0.016%</TableCell>
							<TableCell align="center">0.016%</TableCell>
							<TableCell align="center">0.016%</TableCell>
							<TableCell align="center">0.015%</TableCell>
							<TableCell align="center">0.015%</TableCell>
							<TableCell align="center">0.015%</TableCell>
							<TableCell align="center">0.014%</TableCell>
							<TableCell align="center">0.014%</TableCell>
							<TableCell align="center">0.014%</TableCell>
							<TableCell align="center">0.013%</TableCell>
							<TableCell align="center">0.015%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell
								align="center"
								sx={{
									textTransform: 'uppercase',
									fontWeight: 400,
									background: (theme) => theme.palette.secondary.light,
								}}
							>
								5
							</TableCell>
							<TableCell align="center">0.039%</TableCell>
							<TableCell align="center">0.020%</TableCell>
							<TableCell align="center">0.019%</TableCell>
							<TableCell align="center">0.019%</TableCell>
							<TableCell align="center">0.018%</TableCell>
							<TableCell align="center">0.018%</TableCell>
							<TableCell align="center">0.018%</TableCell>
							<TableCell align="center">0.017%</TableCell>
							<TableCell align="center">0.017%</TableCell>
							<TableCell align="center">0.016%</TableCell>
							<TableCell align="center">0.016%</TableCell>
							<TableCell align="center">0.016%</TableCell>
							<TableCell align="center">0.015%</TableCell>
							<TableCell align="center">0.017%</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default EmploymentProbabilityRatioTable;
