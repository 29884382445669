import { ThemeProvider } from '@mui/material';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';

import FallbackError from './components/molecules/FallbackError';
import BaseLayout from './layout/BaseLayout';
import { BreadcrumbProvider, DrawerProvider } from './shared';
import theme from './theme/default-theme';

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error, query) => {
			if (query.state.data === undefined) {
				toast.error(error.message);
			}
		},
	}),
});

const App = () => {
	return (
		<ErrorBoundary FallbackComponent={FallbackError}>
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<HelmetProvider>
						<DrawerProvider>
							<BreadcrumbProvider>
								<BrowserRouter>
									<Toaster
										position="bottom-center"
										reverseOrder={false}
										gutter={8}
										containerStyle={{}}
										toastOptions={{
											className: '',
											duration: 4000,
											style: {
												borderRadius: '10px',
												background: '#333',
												color: '#fff',
											},
										}}
									/>
									<BaseLayout />
								</BrowserRouter>
							</BreadcrumbProvider>
						</DrawerProvider>
					</HelmetProvider>
				</QueryClientProvider>
			</ThemeProvider>
		</ErrorBoundary>
	);
};

export default App;
