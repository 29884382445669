const convertStringToKebabCase = (str: string) => {
	if (str.length === 0) {
		return '';
	}

	return str.toLocaleLowerCase().split(' ').join('-');
};

export const convertSpaceToUnderscoreThenUppercase = (str: string) => {
	return str.split(' ').join('_').toUpperCase();
};

export default convertStringToKebabCase;
