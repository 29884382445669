import { type Select } from '../types/Select';

export const LOAN_TO_INCOME_RATIO = 'loan-to-income-ratio';
export const DISPOSABLE_INCOME = 'disposable-income';
export const LOSS_PROVISION = 'loan-provision';

export const creditAssessmentDetailsOptions: Select[] = [
	{
		id: '865a1392-3efd-4281-a745-c09ccd9d7efa',
		label: 'Loan to income ratio',
		value: LOAN_TO_INCOME_RATIO,
	},
	{
		id: '865a1392-3efd-4281-a745-c09ccd9d7efb',
		label: 'Disposable income',
		value: DISPOSABLE_INCOME,
	},
	{
		id: '865a1392-3efd-4281-a745-c09ccd9d7efc',
		label: 'Loss provision',
		value: LOSS_PROVISION,
	},
];
