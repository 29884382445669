import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

const LossProvisionConditionTable = () => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
				<TableHead>
					<TableRow
						hover
						sx={{
							background: (theme) => theme.palette.secondary.light,
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
							Condition
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Likelihood
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Loss Given Default
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Remarks
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Death
						</TableCell>
						<TableCell align="center">10%</TableCell>
						<TableCell align="center">{formatNumberToCurrency(9009)}</TableCell>
						<TableCell align="center">aggregate across 72 months</TableCell>
					</TableRow>

					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Divorce
						</TableCell>
						<TableCell align="center">20%</TableCell>
						<TableCell
							align="center"
							sx={{
								// background: (theme) => theme.palette.warning.main,
								background: '#FFB2B2',
								color: (theme) => theme.palette.primary.main,
							}}
						>
							{formatNumberToCurrency(99899)}
						</TableCell>
						<TableCell align="center">aggregate across 72 months</TableCell>
					</TableRow>

					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Unemployment, adjusted for re-employment
						</TableCell>
						<TableCell align="center">30%</TableCell>
						<TableCell align="center">{formatNumberToCurrency(87676)}</TableCell>
						<TableCell align="center">aggregate across 72 months</TableCell>
					</TableRow>

					<TableRow
						hover
						sx={{
							// background: (theme) => theme.palette.success.main,
							background: '#EAFFC0',
							'&:last-child td, &:last-child th': { border: 0 },
						}}
					>
						<TableCell component="th" scope="row">
							Aggregate
						</TableCell>
						<TableCell align="center">60%</TableCell>
						<TableCell align="center">{formatNumberToCurrency(100000)}</TableCell>
						<TableCell align="center"></TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default LossProvisionConditionTable;
