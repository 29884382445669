/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable-next-line prettier/prettier */
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, type OutlinedInputProps, type SxProps } from '@mui/material';
import { useField } from 'formik';
import { type ComponentProps, type FunctionComponent } from 'react';

import { type Select as SelectType } from '../../types/Select';
import convertStringToKebabCase from '../../utils/convert-string-to-kebab-case';
import InputHelperText from '../atoms/InputHelperText';

type SelectInputFieldPropsTypes = ComponentProps<'select'> &
	OutlinedInputProps & {
		label: string;
		items: SelectType[];
		title?: string;
		boxStyle?: SxProps;
		titleStyle?: SxProps;
		inputStyle?: SxProps;
		isRequired?: boolean;
		fullWidth?: boolean;
	};

const SelectInputField: FunctionComponent<SelectInputFieldPropsTypes> = ({
	items = [],
	boxStyle,
	inputStyle,
	titleStyle,
	label = 'Enter value',
	title = '',
	rows = 1,
	isRequired = false,
	fullWidth = false,
	...props
}) => {
	const [field, meta] = useField(props as string);
	const convertLabelStringToTestidPrefix = convertStringToKebabCase(label);

	return (
		<Box sx={boxStyle} data-testid={`${convertLabelStringToTestidPrefix}-input-box`}>
			{title.length > 0 && (
				<Typography
					variant="subtitle1"
					pb={1}
					sx={{ color: (theme) => `${theme.palette.secondary.dark}`, ...titleStyle }}
				>
					{title}
				</Typography>
			)}

			<FormControl
				variant="outlined"
				fullWidth={fullWidth}
				error={!field.value && !(meta?.error === null) && !!meta?.touched}
				data-testid={`${convertLabelStringToTestidPrefix}-select-input-control`}
			>
				<InputLabel
					htmlFor={`outlined-select-input-adornment-${label}`}
					data-testid={`${convertLabelStringToTestidPrefix}-select-input-label`}
				>
					<span>
						{label}
						{isRequired && <span style={{ color: 'red' }}> * </span>}
					</span>
				</InputLabel>

				<Select
					id={`outlined-select-input-adornment-${label}`}
					label={label}
					sx={inputStyle}
					minRows={rows}
					data-testid={`${convertLabelStringToTestidPrefix}-select-input-field`}
					{...props}
					{...field}
				>
					<MenuItem value="" data-testid={`${convertStringToKebabCase('')}-select-item`}>
						<em>NONE</em>
					</MenuItem>

					{items?.map((item) => (
						<MenuItem
							key={item?.id}
							value={item?.value}
							data-testid={`${convertStringToKebabCase(item.label)}-select-input-item`}
						>
							{item?.label}
						</MenuItem>
					))}
				</Select>

				{isRequired && meta?.error !== null && meta?.touched && (
					<InputHelperText error={meta.error} testid={`${convertLabelStringToTestidPrefix}-select-input-error-text`} />
				)}
			</FormControl>
		</Box>
	);
};

export default SelectInputField;
