import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { drawerWidth } from '../../constants/layout';
import useDrawer from '../../hooks/useDrawer';
import { closeSidebarDrawer, openSidebarDrawer } from '../../shared';
import NavigationMenuItems from './NavigationMenuItems';

interface Props {
	// eslint-disable-next-line react/require-default-props
	window?: () => Window;
}

const ResponsiveDrawer = (props: Props) => {
	const { state: drawer, dispatch } = useDrawer();
	const { window } = props;

	const handleDrawerToggle = () => {
		if (drawer.open) {
			closeSidebarDrawer(dispatch);
		} else {
			openSidebarDrawer(dispatch);
		}
	};

	// eslint-disable-next-line prettier/prettier
	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box
			component="nav"
			sx={{
				width: { sm: drawerWidth },
				flexShrink: { sm: 0 },
			}}
			aria-label="mailbox folders"
		>
			<Drawer
				container={container}
				variant="temporary"
				open={drawer.open}
				onClose={handleDrawerToggle}
				ModalProps={{ keepMounted: true }}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
			>
				<NavigationMenuItems />
			</Drawer>

			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth + 2,
					},
				}}
				open={drawer.open}
			>
				<NavigationMenuItems />
			</Drawer>
		</Box>
	);
};

export default ResponsiveDrawer;
