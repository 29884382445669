const numberFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const formatNumberToCurrency = (number: number): string => {
	return numberFormatter.format(number);
};

export const reverseCurrencyToFloat = (number: string): number => {
	const replaceUSDwithEmptyString = number.replace('$', '');
	const replaceCommaWithEmptyString = replaceUSDwithEmptyString.replaceAll(',', '');
	return parseFloat(replaceCommaWithEmptyString);
};

export const reversePercentageValueToFloat = (number: string): number => {
	const replacePercentageWithEmptyString = number.replace('%', '');
	return parseFloat(replacePercentageWithEmptyString);
};

export const removeCommasAndParseIntoNumber = (input: string): number => {
	return parseFloat(input.split(',').join(''));
};
