import { useEffect } from 'react';
import useBreadcrumb from '../../hooks/useBreadcrumb';
import { setBreadcrumbText } from '../../shared';

const Dashboard = () => {
	const { dispatch } = useBreadcrumb();

	useEffect(() => {
		setBreadcrumbText(dispatch, 'Dashboard');
	}, [dispatch]);

	return <div></div>;
};

export default Dashboard;
