import { createTheme } from '@mui/material/styles';

export const defaultTheme = {
	root: {},
	type: 'light',
	primary: {
		main: '#000032',
		dark: '#000000',
	},
	secondary: {
		main: '#FFFFFF',
		light: '#F4F6F6',
		contrastText: '#ADADAD',
		dark: '#818080',
	},
	info: {
		main: '#FFBEFF',
		light: '#468189',
	},
	success: {
		main: '#C8EC82',
		dark: '#1A75FF',
		light: '#2D9CDB',
	},
	warning: {
		main: '#F8254C',
		light: '#F9C74F',
		dark: '#E27E36',
	},
};

const theme = createTheme({
	palette: defaultTheme,
	typography: {
		fontFamily: ['Inter', 'sans-serif'].join(','),
	},
});

export default theme;
