// eslint-disable-next-line prettier/prettier
import { Button, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Form, Formik } from 'formik';
import { type FunctionComponent } from 'react';

import initialState from '../../pages/credit-assessment/validations/initial-state';
import validationSchema from '../../pages/credit-assessment/validations/validation-schema';
import { defaultTheme } from '../../theme/default-theme';
import AutocompleteInputField from '../molecules/AutocompleteInputField';
import TextareaInputField from '../molecules/TextareaInputField';

interface AssignToFormProps {
	toggleDrawer: (event: any) => void;
}

const AssignToForm: FunctionComponent<AssignToFormProps> = ({ toggleDrawer = null }) => {
	const handleCloseDetailsModal = (event: any) => {
		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
		if (toggleDrawer) {
			toggleDrawer(event);
		}
	};

	return (
		<Formik initialValues={initialState} validationSchema={validationSchema} onSubmit={() => {}}>
			<fieldset style={{ border: 'none' }}>
				<Form>
					<Box
						sx={{
							border: `1px solid ${defaultTheme.primary.main}`,
							marginTop: 4,
							borderRadius: '5px',
							p: 4,
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
						}}
					>
						<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Grid item xs={3} sm={3} md={3}>
								<Typography sx={{ fontWeight: 'semibold' }}>
									Assign to <span style={{ color: defaultTheme.warning.main }}>*</span>
								</Typography>
							</Grid>

							<Grid item xs={9} sm={9} md={9}>
								<AutocompleteInputField
									fullWidth
									name="assignTo"
									title=""
									titleStyle={{ color: defaultTheme.primary.dark }}
									label=""
									placeholder="Select assignee"
									boxStyle={{ display: 'flex', justifyContent: 'start', alignItems: 'start', gap: 5 }}
									options={[
										{ id: '100', label: 'John Smith', value: 100 },
										{ id: '101', label: 'Mary Jones', value: 101 },
										{ id: '102', label: 'Peter Brown', value: 102 },
									]}
								/>
							</Grid>
						</Grid>

						<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
							<Grid item xs={3} sm={3} md={3}>
								<Typography sx={{ fontWeight: 'semibold' }}>
									Note <span style={{ color: defaultTheme.warning.main }}>*</span>
								</Typography>
							</Grid>

							<Grid item xs={9} sm={9} md={9}>
								<TextareaInputField
									fullWidth
									name="note"
									title=""
									titleStyle={{ color: defaultTheme.primary.dark }}
									label="Some label"
									placeholder="Write a note"
									inputStyle={{
										display: 'inline-block',
										width: '100%',
										border: `1px solid ${defaultTheme.secondary.contrastText}`,
										borderRadius: '5px',
										padding: '5px',
										fontSize: '16px',
									}}
								/>
							</Grid>
						</Grid>

						<Box sx={{ display: 'flex', gap: 2 }}>
							<Button type="submit" variant="contained">
								Assign
							</Button>

							<Button
								type="button"
								variant="outlined"
								sx={{ border: '1px solid transparent' }}
								onClick={handleCloseDetailsModal}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</Form>
			</fieldset>
		</Formik>
	);
};

export default AssignToForm;
