import { Search } from '@mui/icons-material';
// eslint-disable-next-line @typescript-eslint/consistent-type-imports, prettier/prettier
import { Autocomplete, Box, FormControl, InputLabel, OutlinedInputProps, SxProps, TextField, Typography } from '@mui/material';
import { useField } from 'formik';
import { type ComponentProps, type FunctionComponent, type SyntheticEvent } from 'react';

import { type Select } from '../../types/Select';
import convertStringToKebabCase from '../../utils/convert-string-to-kebab-case';
import InputHelperText from '../atoms/InputHelperText';

type AutocompleteInputFieldPropsTypes = ComponentProps<'input'> &
	OutlinedInputProps & {
		options: Select[];
		label: string;
		title?: string;
		boxStyle?: SxProps;
		titleStyle?: SxProps;
		inputStyle?: SxProps;
		rows?: number;
		isRequired?: boolean;
		fullWidth?: boolean;
		successValidation?: boolean;
		validation?: any;
	};

const AutocompleteInputField: FunctionComponent<AutocompleteInputFieldPropsTypes> = ({
	options,
	boxStyle,
	inputStyle,
	titleStyle,
	label = 'Enter value',
	title = '',
	rows = 1,
	isRequired = false,
	fullWidth = false,
	successValidation = false,
	validation = null,
	...props
}) => {
	const [field, meta, helpers] = useField(props as string);
	const convertLabelStringToTestidPrefix = convertStringToKebabCase(label);

	const handleChangeSelectOption = (event: SyntheticEvent, value: Select | null) => {
		helpers.setTouched(true);
		helpers.setValue(value?.value);
	};

	return (
		<Box sx={boxStyle} data-testid={`${convertLabelStringToTestidPrefix}-autocomplete-input-box`}>
			{title.length > 0 && (
				<Typography
					variant="subtitle1"
					pb={1}
					sx={{ color: (theme) => `${theme.palette.secondary.dark}`, ...titleStyle }}
				>
					{title}
				</Typography>
			)}

			<FormControl
				variant="outlined"
				fullWidth={fullWidth}
				error={!(meta?.error == null) && !!meta?.touched}
				data-testid={`${convertLabelStringToTestidPrefix}-autocomplete-input-control`}
			>
				<InputLabel
					htmlFor={`outlined-adornment-${label}`}
					data-testid={`${convertLabelStringToTestidPrefix}-autocomplete-input-label`}
				>
					<span>
						{label}
						{isRequired && <span style={{ color: 'red' }}> * </span>}
					</span>
				</InputLabel>

				<Autocomplete
					disablePortal
					options={options}
					id={`outlined-autocomplete-adornment-${label}`}
					sx={inputStyle}
					data-testid={`${convertLabelStringToTestidPrefix}-input`}
					renderInput={(params) => (
						<TextField
							name={field.name}
							{...params}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<>
										<Search />
									</>
								),
							}}
						/>
					)}
					getOptionKey={(option) => option?.id}
					getOptionLabel={(option) => option?.label}
					isOptionEqualToValue={(option, value) => option.value === value.value}
					onChange={handleChangeSelectOption}
				/>

				{isRequired && meta?.error != null && meta?.touched && (
					<InputHelperText
						error={meta.error}
						testid={`${convertLabelStringToTestidPrefix}-autocomplete-input-error-text`}
					/>
				)}
			</FormControl>
		</Box>
	);
};

export default AutocompleteInputField;
