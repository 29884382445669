import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';

// eslint-disable-next-line prettier/prettier
import { APPLICANT1_INCOME_PROBABILITY_RATIO, APPLICANT2_INCOME_PROBABILITY_RATIO, incomeProbabilityRationOptions } from '../../constants/income-probability-ratio-options';
import EmploymentCalculationsTable from '../organisms/EmploymentCalculationsTable';
import EmploymentProbabilityRatioTable from '../organisms/EmploymentProbabilityRatioTable';
import LossProvisionConditionTable from '../organisms/LossProvisionConditionTable';
import LossProvisionHEMCalculationTable from '../organisms/LossProvisionHEMCalculationTable';

const LossProvisionDetails = () => {
	const [selectedIncomeProbabilityRatio, setSelectedIncomeProbabilityRatio] = useState(
		incomeProbabilityRationOptions[0]?.value as string
	);

	const handleChangeIncomeProbabilityRatio = (event: { target: { value: string } }) => {
		const { value } = event.target;
		setSelectedIncomeProbabilityRatio(value);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} sm={12} md={12}>
					<Box>
						<LossProvisionConditionTable />
					</Box>
				</Grid>
			</Grid>

			<Box my={5} />

			<Grid container spacing={2}>
				<LossProvisionHEMCalculationTable />
			</Grid>

			<Box my={5} />

			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={6}>
					<Box>
						<Typography variant="h5" mb={2} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							Applicant 1
						</Typography>

						<Typography variant="body2" mb={2} textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							Employment Calculations
						</Typography>

						<EmploymentCalculationsTable />
					</Box>
				</Grid>

				<Grid item xs={12} sm={12} md={6}>
					<Box>
						<Typography variant="h5" mb={2} fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							Applicant 2
						</Typography>

						<Typography variant="body2" mb={2} textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							Employment Calculations
						</Typography>

						<EmploymentCalculationsTable />
					</Box>
				</Grid>
			</Grid>

			<Box my={5} />

			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12}>
					<Box>
						<Typography mb={2} variant="h5" fontWeight="bold" textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>
							Employment probability ratio
						</Typography>

						<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
							<Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 2 }}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel htmlFor="outlined-select-input-adornment-for-approval-status-label">
										<span>Selected Assessment Outcome</span>
									</InputLabel>

									<Select
										labelId="outlined-select-input-adornment-for-approval-status-label"
										id="outlined-select-input-adornment-for-approval-status"
										label="Selected Assessment Outcome"
										sx={{ width: 300 }}
										value={selectedIncomeProbabilityRatio}
										onChange={handleChangeIncomeProbabilityRatio}
									>
										{incomeProbabilityRationOptions?.map((item) => (
											<MenuItem key={item?.id} value={item?.value}>
												{item?.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={12} sm={12} md={12}>
					{selectedIncomeProbabilityRatio === APPLICANT1_INCOME_PROBABILITY_RATIO && (
						<EmploymentProbabilityRatioTable />
					)}

					{selectedIncomeProbabilityRatio === APPLICANT2_INCOME_PROBABILITY_RATIO && (
						<EmploymentProbabilityRatioTable />
					)}
				</Grid>
			</Grid>

			<Box my={5} />
		</>
	);
};

export default LossProvisionDetails;
