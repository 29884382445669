import { Box, List, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { Link, NavLink, useLocation } from 'react-router-dom';

import OurLegUp from '../../assets/svgs/OurLegUp-rgb';
import { drawerWidth, headerHeight } from '../../constants/layout';
import { sidebarRoutes } from '../../routes/sidebar-routes';
import Copyright from './Copyright';

const NavigationMenuItems = () => {
	const location = useLocation();

	return (
		<Box
			sx={{
				minHeight: '100vh',
				background: (theme) => theme.palette.primary.main,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				pb: 2,
			}}
		>
			<Box>
				<Box
					sx={{
						py: 1,
						height: `${headerHeight + 10}px`,
						width: `${drawerWidth}px`,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						background: (theme) => theme.palette.primary.main,
					}}
				>
					<Link to="/" style={{ textDecoration: 'none' }}>
						<OurLegUp fill="#fff" />
					</Link>
				</Box>

				<List sx={{ background: (theme) => theme.palette.primary.main }}>
					{sidebarRoutes.map((item) => (
						<Box key={item.id}>
							<Typography
								sx={{
									color: (theme) => theme.palette.secondary.main,
									fontWeight: 'bold',
									m: 1.5,
									mt: 3,
								}}
							>
								{item.title}
							</Typography>

							{item.items.map((nav) => (
								<NavLink
									key={nav.id}
									to={nav.path}
									style={{ textDecoration: 'none' }}
									className={(navItem) => (navItem.isActive ? 'active' : '')}
								>
									<ListItem
										disablePadding
										className="sidebar-list"
										sx={{
											my: 0.5,
											color: (theme) => theme.palette.secondary.main,
											'&:hover': {
												background: (theme) => theme.palette.success.main,
												color: (theme) => `${theme.palette.primary.main} !important`,
											},
										}}
									>
										<ListItemButton
											className={`sidebar-list-button ${location?.pathname === nav.path ? 'active' : ''}`}
										>
											<ListItemIcon
												className="sidebar-list-button--icon"
												sx={{
													color: (theme) => theme.palette.secondary.main,
													minWidth: 40,
												}}
											>
												<nav.icon />
											</ListItemIcon>

											<Typography className="sidebar-list-button--text" sx={{ fontWeight: 'bold' }}>
												{nav.name}
											</Typography>
										</ListItemButton>
									</ListItem>
								</NavLink>
							))}
						</Box>
					))}
				</List>
			</Box>

			<Copyright />
		</Box>
	);
};

export default NavigationMenuItems;
