import { createContext } from 'react';

import initialState from '../states/drawerState';
import { type Context } from '../types/Drawer';

const contextInitialState: Context = {
	state: initialState,
	dispatch: () => {},
};

const DrawerContext = createContext<Context>(contextInitialState);

export default DrawerContext;
