import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

const EmploymentCalculationsTable = () => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
					<TableHead>
						<TableRow
							hover
							sx={{
								background: (theme) => theme.palette.secondary.light,
								color: (theme) => theme.palette.secondary.dark,
							}}
						>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
								Duration
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								Likelihood of unemployment
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
								Reemployment
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								Month 1
							</TableCell>
							<TableCell align="center">0.020%</TableCell>
							<TableCell align="center">50%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								Month 6
							</TableCell>
							<TableCell align="center">0.020%</TableCell>
							<TableCell align="center">30%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								Month 12
							</TableCell>
							<TableCell align="center">0.022%</TableCell>
							<TableCell align="center">22%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								Month 36
							</TableCell>
							<TableCell align="center">0.025%</TableCell>
							<TableCell align="center">16%</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								Month 72
							</TableCell>
							<TableCell align="center">0.030%</TableCell>
							<TableCell align="center">10%</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>

			<Box my={4} />

			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
					<TableHead>
						<TableRow
							hover
							sx={{
								background: (theme) => theme.palette.secondary.light,
								color: (theme) => theme.palette.secondary.dark,
							}}
						>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
								Calculation
							</TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center"></TableCell>
							<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center"></TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								0.02% X 50%
							</TableCell>
							<TableCell align="center">Default Event?</TableCell>
							<TableCell align="center">Loss given default</TableCell>
						</TableRow>

						<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								Upto Reemployment month 12
							</TableCell>
							<TableCell align="center">{formatNumberToCurrency(10000)}</TableCell>
							<TableCell align="center">{formatNumberToCurrency(10000)}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default EmploymentCalculationsTable;
