// import NewCreditAssessment from '../pages/new-credit-assessment/NewCreditAssessment';
import CreditAssessmentDetails from '../pages/credit-assessment-details/CreditAssessmentDetails';
import CreditAssessment from '../pages/credit-assessment/CreditAssessment';
import Dashboard from '../pages/dashboard/Dashboard';
import PasswordLessSignin from '../pages/password-less-signin/PasswordLessSignin';
import PasswordLessSignup from '../pages/password-less-signup/PasswordLessSignup';
import type Route from '../types/Route';

const routes: Route[] = [
	{
		id: '0425abe8-399b-4bd3-89b8-30c3d3c041fc',
		name: 'Sign In',
		component: PasswordLessSignin,
		path: '/signin',
		icon: null,
		exact: true,
		protected: false,
	},
	{
		id: '622cfd8e-7e08-4f4d-ac19-f07ddce8956f',
		name: 'Sign Up',
		component: PasswordLessSignup,
		path: '/signup',
		icon: null,
		exact: true,
		protected: false,
	},
	{
		id: '894c6a0d-9f1c-4a94-bb9e-0de5c1d8d13a',
		name: 'Dashboard',
		component: Dashboard,
		path: '/dashboard',
		icon: null,
		exact: true,
		protected: false,
	},
	{
		id: '7cbe6ff1-7d63-46fa-bf27-a3f9b77c1de2',
		name: 'Credit Assessment',
		component: CreditAssessment,
		path: '/credit-assessment',
		icon: null,
		exact: true,
		protected: false,
	},
	{
		id: '7cbe6ff1-7d63-46fa-bf27-a3f9b77c1sdh7',
		name: 'Credit Assessment Details',
		component: CreditAssessmentDetails,
		path: '/credit-assessment/:id',
		icon: null,
		exact: true,
		protected: false,
	},
	// {
	// 	id: 'c3f2960a-5669-4471-96b8-43015fff0f2c',
	// 	name: 'New Credit Assessment',
	// 	component: NewCreditAssessment,
	// 	path: '/credit-assessment/new',
	// 	icon: null,
	// 	exact: true,
	// 	protected: false,
	// },
];

export default routes;
