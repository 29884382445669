const initialState = {
	approval: '',
	approvalStatus: '',
	approvalDate: '',
};

export const notesInitialState = {
	note: '',
};

export default initialState;
