import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { type FunctionComponent } from 'react';
import { type Data } from '../../data/loan-applications-data';

interface CreditAssessmentBasicInformationProps {
	data: Data | null;
}

const CreditAssessmentBasicInformation: FunctionComponent<CreditAssessmentBasicInformationProps> = ({ data }) => {
	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={12} mt={4}>
				<Box px={4}>
					<Accordion component={Box} defaultExpanded>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="basic-information-content"
							id="basic-information-header"
						>
							<Typography>Basic Information</Typography>
						</AccordionSummary>

						<AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Loan Type:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.loanType}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Applicant Type:</Typography>
								<Typography sx={{ flex: 1 }}>{data?.applicantType}</Typography>
							</Box>

							<Box sx={{ display: 'flex', gap: 5 }}>
								<Typography sx={{ flex: 1 }}>Private Health Insurance:</Typography>
								<Typography sx={{ flex: 1 }}>
									{data?.privateHealthInsurance?.toUpperCase() === 'TRUE' ? 'Yes' : 'No'}
								</Typography>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
			</Grid>
		</Grid>
	);
};

export default CreditAssessmentBasicInformation;
