import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik, type FormikValues } from 'formik';
import { type FunctionComponent } from 'react';
import toast from 'react-hot-toast';

import notesMutation from '../../apis/graphql/mutation/notes-mutation';
import { notesInitialState } from '../../pages/credit-assessment/validations/initial-state';
import { notesValidationSchema } from '../../pages/credit-assessment/validations/validation-schema';
import { defaultTheme } from '../../theme/default-theme';
import { type Note } from '../../types/Notes';
import TextareaInputField from '../molecules/TextareaInputField';

interface NotesFromProps {
	toggleNotesForm: () => void;
	loanId: string;
}

const NotesFrom: FunctionComponent<NotesFromProps> = ({ toggleNotesForm, loanId = '' }) => {
	const queryClient = useQueryClient();

	// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-confusing-void-expression
	const handleClose = () => toggleNotesForm && toggleNotesForm();

	const { isPending, mutateAsync, isError, error } = useMutation({
		mutationFn: async (data: Note) => await notesMutation(data),
		onSuccess: () => {
			toast.success('Note added successfully');
			handleClose();
			queryClient.invalidateQueries({ queryKey: ['credit_assessment_notes'] });
		},
	});

	const handleSubmit = async (values: FormikValues, fns: any) => {
		const data: Note = {
			loan_id: loanId,
			note: values.note,
		};

		try {
			await mutateAsync(data);
		} catch (error) {
			console.log({ error });
		}
	};

	return (
		<>
			<Formik initialValues={notesInitialState} validationSchema={notesValidationSchema} onSubmit={handleSubmit}>
				<fieldset style={{ border: 'none' }} disabled={isPending}>
					<Form>
						<Box
							sx={{
								border: `1px solid ${defaultTheme.secondary.dark}`,
								marginTop: 2,
								borderRadius: '5px',
								p: 4,
								display: 'flex',
								flexDirection: 'column',
								gap: 3,
							}}
						>
							{isError && <Alert severity="error">{error.message}</Alert>}

							<Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
								<Grid item xs={3} sm={3} md={3}>
									<Typography sx={{ fontWeight: 'semibold' }}>
										Note <span style={{ color: defaultTheme.warning.main }}>*</span>
									</Typography>
								</Grid>

								<Grid item xs={9} sm={9} md={9}>
									<TextareaInputField
										isRequired
										fullWidth
										name="note"
										title=""
										titleStyle={{ color: defaultTheme.primary.dark }}
										label="Some label"
										placeholder="Write a note"
										inputStyle={{
											display: 'inline-block',
											width: '100%',
											border: `1px solid ${defaultTheme.secondary.contrastText}`,
											borderRadius: '5px',
											padding: '5px',
											fontSize: '16px',
										}}
									/>
								</Grid>
							</Grid>

							<Box sx={{ display: 'flex', gap: 2 }}>
								<Button
									type="submit"
									variant="contained"
									endIcon={isPending && <CircularProgress size={18} color="inherit" />}
								>
									Save
								</Button>

								<Button type="button" variant="outlined" sx={{ border: '1px solid transparent' }} onClick={handleClose}>
									Cancel
								</Button>
							</Box>
						</Box>
					</Form>
				</fieldset>
			</Formik>
		</>
	);
};

export default NotesFrom;
