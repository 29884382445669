export interface Data {
	id: number;
	uuid: string;
	loanType: string;
	applicantType: string;
	loanBalance: string;
	propertyValuation: string;
	propertyAddress: string;
	lga: string;
	status: number;
	incomeToLoanRatio: string;
	shareDisposableIncomeBuffer: string;
	lossShare: string;
	loanDocuments: string;
	privateHealthInsurance: string;
	originalLoan: string;
	interestRate: string;
	numberOfApplicants: number;
	propertyType: string;
	strataTitle: string;
	suburb: string;
	state: string;
	postcode: string;
	sale: string;
	valuationDate: string;
	termOfLoan: string;
	oluLoanDuration: string;
	applicant1Age: string;
	applicant1Female: string;
	applicant1PAYGIncome: string;
	applicant1otherIncome: string;
	applicant1PublicSector: string;
	applicant1Occupation: number;
	applicant1DescriptionOfOccupation: string;
	applicant1DescriptionOfIndustry: string;
	applicant1Industry: number;
	applicant1DurationOfEmployment: string;
	applicant1EmploymentType: string;
	applicant2Age: string;
	applicant2Female: string;
	applicant2PAYGIncome: string;
	applicant2otherIncome: string;
	applicant2PublicSector: string;
	applicant2Occupation: number;
	applicant2DescriptionOfOccupation: string;
	applicant2DescriptionOfIndustry: string;
	applicant2Industry: number;
	applicant2DurationOfEmployment: string;
	applicant2EmploymentType: string;
	approvalStatus: string;
}

export interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
}

export const headerRows: readonly HeadCell[] = [
	{
		id: 'id',
		numeric: false,
		disablePadding: true,
		label: 'id',
	},
	{
		id: 'loanType',
		numeric: true,
		disablePadding: false,
		label: 'loan type',
	},
	{
		id: 'applicantType',
		numeric: true,
		disablePadding: false,
		label: 'applicant type',
	},
	{
		id: 'loanBalance',
		numeric: true,
		disablePadding: false,
		label: 'loan balance',
	},
	{
		id: 'propertyValuation',
		numeric: true,
		disablePadding: false,
		label: 'property valuation',
	},
	{
		id: 'propertyAddress',
		numeric: true,
		disablePadding: false,
		label: 'property address',
	},
	{
		id: 'lga',
		numeric: true,
		disablePadding: false,
		label: 'lga',
	},
	{
		id: 'status',
		numeric: true,
		disablePadding: false,
		label: 'status',
	},
];

export const rows: Data[] = [
	{
		id: 100,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '1045000',
		propertyValuation: '1100000',
		propertyAddress: '10 CHURCHILL STREET',
		lga: 'FAIRFIELD NSW 2165',
		status: 0,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 101,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '2431000',
		propertyValuation: '2860000',
		propertyAddress: '299 BRONTE ROAD, WAVERLEY NSW 2093',
		lga: 'FAIRFIELD NSW 2165',
		status: 1,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 102,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '4675000',
		propertyValuation: '5500000',
		propertyAddress: '7 CORMACK STREET ,BALGOWLAH NSW 2452',
		lga: 'Northern Beaches',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 103,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '1000000',
		propertyValuation: '1100000',
		propertyAddress: '23 BARTON STREET, HOLLAND PARK WEST QLD 4121',
		lga: 'BCC-STEPHENS',
		status: 1,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 104,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '864500',
		propertyValuation: '910000',
		propertyAddress: '15 HIGH STREET, MILTON, QLD 4064',
		lga: 'BCC-ITHACA',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 105,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '1900000',
		propertyValuation: '2000000',
		propertyAddress: '7 COLTON STREET, HIGHGATE HILL, QLD 4101',
		lga: 'BCC-SOUTH BRISBANE',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 106,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Single',
		loanBalance: '665000',
		propertyValuation: '700000',
		propertyAddress: '9 YOUNG STREET, EPPING, VIC 3076',
		lga: 'WHITTLESEA CITY',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 107,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '3610000',
		propertyValuation: '3800000',
		propertyAddress: '27 SCOTT GROVE, GLEN IRIS, VIC 3146',
		lga: 'STONNINGTON CITY',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 108,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '2850000',
		propertyValuation: '3000000',
		propertyAddress: '1 ROE STREET, GRIFFITH, ACT 2603',
		lga: 'ACT',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 109,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Couple',
		loanBalance: '1615000',
		propertyValuation: '1700000',
		propertyAddress: '36 BIRDWOOD STREET, HUGHES, ACT 2605',
		lga: 'ACT',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 110,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Single',
		loanBalance: '684000',
		propertyValuation: '720000',
		propertyAddress: '69 WARWICK STREET, HOBART, TAS 7000',
		lga: 'HOBART CITY COUNCIL',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
	{
		id: 111,
		uuid: 'random-string-value',
		loanType: 'New',
		applicantType: 'Single',
		loanBalance: '570000',
		propertyValuation: '600000',
		propertyAddress: '49/580 HAY STREET, PERTH, WA 6000',
		lga: 'PERTH, CITY OF',
		status: 2,
		incomeToLoanRatio: '6.1',
		shareDisposableIncomeBuffer: '0.77',
		lossShare: '0.049',
		loanDocuments: 'https://securefiles.net/2342f2343',
		privateHealthInsurance: 'Yes',
		originalLoan: 'N/A',
		interestRate: '6.55%',
		numberOfApplicants: 2,
		propertyType: 'Established property',
		strataTitle: 'No',
		suburb: 'Fairfield',
		state: 'NSW',
		postcode: '2165',
		sale: 'Yes',
		valuationDate: '01/01/2020',
		termOfLoan: '360 Months',
		oluLoanDuration: '44 Months',
		applicant1Age: '42',
		applicant1Female: 'No',
		applicant1PAYGIncome: '150000',
		applicant1otherIncome: '0',
		applicant1PublicSector: 'No',
		applicant1Occupation: 242,
		applicant1DescriptionOfOccupation: 'Tertiary Education Teachers',
		applicant1DescriptionOfIndustry: 'Tertiary Education',
		applicant1Industry: 145,
		applicant1DurationOfEmployment: '49 Months',
		applicant1EmploymentType: 'Permanent',
		applicant2Age: '40',
		applicant2Female: 'Yes',
		applicant2PAYGIncome: '120000',
		applicant2otherIncome: '0',
		applicant2PublicSector: 'Yes',
		applicant2Occupation: 242,
		applicant2DescriptionOfOccupation: 'Secondary Education Teachers',
		applicant2DescriptionOfIndustry: 'Secondary Education',
		applicant2Industry: 145,
		applicant2DurationOfEmployment: '56 Months',
		applicant2EmploymentType: 'Permanent',
		approvalStatus: '',
	},
];
