import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

const HEMCalculationsTable = () => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
				<TableHead>
					<TableRow
						hover
						sx={{
							background: (theme) => theme.palette.secondary.light,
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
							Category
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Base Expense
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Stated Expense
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Dual income no kids
						</TableCell>
						<TableCell align="center">{formatNumberToCurrency(100000)}</TableCell>
						<TableCell align="center">{formatNumberToCurrency(13344)}</TableCell>
					</TableRow>

					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Dual income left over expenses form 2020
						</TableCell>
						<TableCell align="center">{formatNumberToCurrency(100000)}</TableCell>
						<TableCell align="center">{formatNumberToCurrency(13344)}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default HEMCalculationsTable;
