import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { type FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import type Route from '../../types/Route';

interface MenuItemWithIconPropsTypes {
	item: Route;
}

const MenuItemWithIcon: FunctionComponent<MenuItemWithIconPropsTypes> = ({ item }) => {
	const menuItemWithIcon = (
		<MenuItem>
			<ListItemIcon
				sx={{
					display: 'flex',
					justifyContent: 'start',
					alignItems: 'center',
					gap: 1.5,
					color: (theme) => theme.palette.primary.main,
				}}
			>
				<item.icon />
			</ListItemIcon>

			<ListItemText primary={item.name} sx={{ color: (theme) => theme.palette.primary.main }} />
		</MenuItem>
	);

	if (item?.openInNewTab ?? false) {
		return (
			<>
				<a href={item.path} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
					{menuItemWithIcon}
				</a>

				{(item.divider ?? false) && <Divider />}
			</>
		);
	}

	return (
		<>
			<Link to={item.path} style={{ textDecoration: 'none' }}>
				{menuItemWithIcon}
			</Link>

			{(item.divider ?? false) && <Divider />}
		</>
	);
};

export default MenuItemWithIcon;
