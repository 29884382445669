import { Grid } from '@mui/material';

import HEMEmploymentTable from '../molecules/HEMEmploymentTable';
import HEMLgaTable from '../molecules/HEMLgaTable';
import HEMReEmploymentTable from '../molecules/HEMReEmploymentTable';

const LossProvisionHEMCalculationTable = () => {
	return (
		<>
			<Grid item xs={12} sm={12} md={4}>
				<HEMLgaTable />
			</Grid>

			<Grid item xs={12} sm={12} md={4}>
				<HEMEmploymentTable />
			</Grid>

			<Grid item xs={12} sm={12} md={4}>
				<HEMReEmploymentTable />
			</Grid>
		</>
	);
};

export default LossProvisionHEMCalculationTable;
