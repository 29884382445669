import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useReducer, type FunctionComponent } from 'react';

import notesByLoanIdQuery from '../../apis/graphql/query/notes-by-loan-id-query';
import { type Data } from '../../data/loan-applications-data';
import theme from '../../theme/default-theme';
import { type Note } from '../../types/Notes';
import isEmpty from '../../utils/is-empty';
import NotesFrom from './NotesForm';

interface CreditAssessmentNotesProps {
	data: Data | null;
}

const CreditAssessmentNotes: FunctionComponent<CreditAssessmentNotesProps> = ({ data }) => {
	const [notesForm, toggleNotesForm] = useReducer((state: boolean) => !state, false);

	const { data: notes } = useQuery({
		queryKey: ['credit_assessment_notes', data?.uuid],
		queryFn: async ({ queryKey }) => await notesByLoanIdQuery(queryKey[1]!),
	});

	return (
		<Grid container>
			<Grid item xs={12} sm={12} md={12} mt={4}>
				<Box px={4}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 3,
							borderRadius: 1,
						}}
					>
						<Box>
							<Button
								variant="outlined"
								startIcon={<NoteAltOutlinedIcon />}
								sx={{
									color: (theme) => theme.palette.secondary.dark,
									borderColor: (theme) => theme.palette.secondary.dark,
								}}
								onClick={toggleNotesForm}
							>
								Add new note
							</Button>
						</Box>
					</Box>
				</Box>
			</Grid>

			{/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
			{notesForm && data && (
				<Grid item xs={12} sm={12} md={12}>
					<Box px={2}>
						<NotesFrom toggleNotesForm={toggleNotesForm} loanId={data.uuid} />
					</Box>
				</Grid>
			)}

			{!isEmpty(notes) && (
				<Grid item xs={12} sm={12} md={12} mt={2}>
					<Box px={4}>
						<Accordion>
							<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
								View all notes
							</AccordionSummary>

							<AccordionDetails>
								<Box px={2}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											gap: 1,
										}}
									>
										{notes.map((note: Note) => (
											<Box
												key={note.id}
												sx={{
													padding: 2,
													background: theme.palette.secondary.light,
													borderRadius: 1,
												}}
											>
												<Typography variant="body1" sx={{ color: (theme) => theme.palette.secondary.dark }}>
													{note.note}
												</Typography>

												<Typography
													variant="body2"
													align="right"
													sx={{ color: (theme) => theme.palette.secondary.dark }}
												>
													{Boolean(note.note_by) && (
														<span>
															Note by: <span style={{ fontWeight: 'bold' }}>{note.note_by}</span>
														</span>
													)}

													{Boolean(note.updated_at) && (
														<span>
															created at{' '}
															<span style={{ fontWeight: 'bold' }}>
																{new Date(note.updated_at!).toLocaleDateString('en-US')}
															</span>
														</span>
													)}
												</Typography>
											</Box>
										))}
									</Box>
								</Box>
							</AccordionDetails>
						</Accordion>
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default CreditAssessmentNotes;
