import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { formatNumberToCurrency } from '../../utils/format-number-to-currency';

const OtherLoanCommitmentsTable = () => {
	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 600 }} aria-label="applicant income table">
				<TableHead>
					<TableRow
						hover
						sx={{
							background: (theme) => theme.palette.secondary.light,
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="left">
							Loan
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Type of Loan
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Value
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Repayments (Per Month)
						</TableCell>
						<TableCell sx={{ textTransform: 'uppercase', fontWeight: 400 }} align="center">
							Outstanding Balance
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Personal
						</TableCell>
						<TableCell align="center">Secured</TableCell>
						<TableCell align="center">{formatNumberToCurrency(100000)}</TableCell>
						<TableCell align="center">{formatNumberToCurrency(1500)}</TableCell>
						<TableCell align="center">$XXXXX</TableCell>
					</TableRow>

					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Car
						</TableCell>
						<TableCell align="center">Unsecured</TableCell>
						<TableCell align="center">{formatNumberToCurrency(150000)}</TableCell>
						<TableCell align="center">{formatNumberToCurrency(2000)}</TableCell>
						<TableCell align="center">$XXXXX</TableCell>
					</TableRow>

					<TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
							Home
						</TableCell>
						<TableCell align="center">Secured</TableCell>
						<TableCell align="center">{formatNumberToCurrency(500000)}</TableCell>
						<TableCell align="center">{formatNumberToCurrency(5000)}</TableCell>
						<TableCell align="center">$XXXXX</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default OtherLoanCommitmentsTable;
