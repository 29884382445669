// eslint-disable-next-line prettier/prettier
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { useState, type FunctionComponent } from 'react';

interface FallbackErrorProps {
	error: Error;
	resetErrorBoundary: (...args: unknown[]) => void;
}

const FallbackError: FunctionComponent<FallbackErrorProps> = ({ error, resetErrorBoundary }) => {
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClearCacheAndContinue = () => {
		// Clear cache and cookies
		window.localStorage.clear();

		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
		}

		resetErrorBoundary();
		handleClose();

		window.location.assign('/');
	};

	console.log('Fallback error:', error);
	console.log('Fallback error message:', error?.message);

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="error-boundary-title"
				aria-describedby="error-boundary-description"
			>
				<DialogTitle id="error-boundary-title">While using the application you may encounter some errors.</DialogTitle>

				<DialogContent>
					<DialogContentText sx={{ color: (theme) => theme.palette.primary.main }} id="error-boundary-description">
						There are several potential solutions for resolving an application error. Here are a few common steps to
						try:
					</DialogContentText>

					<Box sx={{ color: (theme) => theme.palette.secondary.dark }}>
						<Typography mt={2}>
							<span style={{ fontWeight: 'bold' }}>Restart the Application:</span> Sometimes simply closing and
							reopening the application can resolve the error.
						</Typography>

						<Typography mt={1}>
							<span style={{ fontWeight: 'bold' }}>Restart the Device:</span> Rebooting your device can sometimes clear
							out temporary issues causing the error.
						</Typography>

						<Typography mt={1}>
							<span style={{ fontWeight: 'bold' }}>Check for System Updates:</span> Ensure that your operating system is
							up to date. System updates can often resolve compatibility issues.
						</Typography>
					</Box>
				</DialogContent>

				<DialogActions>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							flexDirection: { xs: 'column', sm: 'column', md: 'row' },
							justifyContent: 'space-between',
							gap: 1,
							mx: 2,
							pb: 2,
						}}
					>
						<Button sx={{ textTransform: 'initial' }} variant="contained" onClick={handleClose}>
							Report an issue
						</Button>

						<Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, gap: 1 }}>
							<Button
								sx={{ textTransform: 'initial', '&:hover': { background: (theme) => theme.palette.success.main } }}
								color="success"
								variant="contained"
								onClick={handleClearCacheAndContinue}
							>
								Clear cache and continue
							</Button>

							<Button sx={{ textTransform: 'initial' }} variant="outlined" onClick={handleClose}>
								Close
							</Button>
						</Box>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default FallbackError;
