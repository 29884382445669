const ChevronUp = () => {
	return (
		<svg width="25" height="10" viewBox="0 0 25 10" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1659_8720)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.6272 1.29279C11.8147 1.10532 12.069 1 12.3342 1C12.5993 1 12.8536 1.10532 13.0412 1.29279L18.6982 6.94979C18.8803 7.13839 18.9811 7.39099 18.9788 7.65319C18.9766 7.91538 18.8714 8.1662 18.686 8.35161C18.5006 8.53701 18.2498 8.64218 17.9876 8.64446C17.7254 8.64674 17.4728 8.54594 17.2842 8.36379L12.3342 3.41379L7.38416 8.36379C7.19556 8.54594 6.94296 8.64674 6.68076 8.64446C6.41857 8.64218 6.16775 8.53701 5.98235 8.35161C5.79694 8.1662 5.69177 7.91538 5.68949 7.65319C5.68721 7.39099 5.78801 7.13839 5.97016 6.94979L11.6272 1.29279Z"
					fill="#00857C"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1659_8720">
					<rect width="24" height="10" fill="white" transform="translate(0.833984)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ChevronUp;
