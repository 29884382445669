import { Box, LinearProgress, Toolbar } from '@mui/material';
import { Suspense, type FunctionComponent, type PropsWithChildren } from 'react';

import { drawerWidth, headerHeight } from '../constants/layout';
import RouteConfig from '../routes/RouteConfig';
import routes from '../routes/routes';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

const BaseLayout: FunctionComponent<PropsWithChildren> = () => {
	const { authState } = { authState: { authenticated: true } };

	return (
		<Box sx={{ display: 'flex' }}>
			{authState.authenticated && <Header />}
			{authState.authenticated && <Sidebar />}

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					background: (theme) => theme.palette.secondary.light,
					// minHeight: `calc(100vh - ${headerHeight}px)`,
					minHeight: `calc(100vh - ${headerHeight / 3}px)`,
					mt: authState.authenticated ? 3 : 0,
					p: {
						xs: authState.authenticated ? 1.5 : 0,
						sm: authState.authenticated ? 2 : 0,
						md: authState.authenticated ? 3 : 0,
					},
				}}
			>
				{authState.authenticated && <Toolbar />}

				<Suspense fallback={<LinearProgress color="success" />}>
					<RouteConfig routes={routes} />
				</Suspense>
			</Box>
		</Box>
	);
};

export default BaseLayout;
